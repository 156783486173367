// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-form_container__7UNGW{
    background-color: black;
    opacity: .9;
    position: relative;

    width: 50%;
    height: 68%;

    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: flex-end;
    z-index: 100;

}

.main-form_decor__qp3l7{
    content: "";
    color: #fff;
    display: block;
    position: absolute;
    border: solid 4px rgba(210, 206, 196, .5);
    
    width: 56%;
    height: 60%;

    bottom: 28%;
    right: 32%; 
    pointer-events: none;
    z-index: -1;
}



.main-form_containerFlipped__dF8mD{
    margin-top: auto;
    margin-bottom: 0;

    justify-content: flex-start;
}

.main-form_decorFliped__NOAs3{
    bottom: 0;
    border: solid 4px rgba(255, 255, 0);
}

.main-form_mainContainer__7chIW{
    display: flex;
    height: 100vh;
    gap: 86px;
    

}`, "",{"version":3,"sources":["webpack://./src/components/game-menu/main-form/main-form.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,WAAW;IACX,kBAAkB;;IAElB,UAAU;IACV,WAAW;;IAEX,aAAa;IACb,sBAAsB;;IAEtB,mBAAmB;IACnB,yBAAyB;IACzB,YAAY;;AAEhB;;AAEA;IACI,WAAW;IACX,WAAW;IACX,cAAc;IACd,kBAAkB;IAClB,yCAAyC;;IAEzC,UAAU;IACV,WAAW;;IAEX,WAAW;IACX,UAAU;IACV,oBAAoB;IACpB,WAAW;AACf;;;;AAIA;IACI,gBAAgB;IAChB,gBAAgB;;IAEhB,2BAA2B;AAC/B;;AAEA;IACI,SAAS;IACT,mCAAmC;AACvC;;AAEA;IACI,aAAa;IACb,aAAa;IACb,SAAS;;;AAGb","sourcesContent":[".container{\n    background-color: black;\n    opacity: .9;\n    position: relative;\n\n    width: 50%;\n    height: 68%;\n\n    display: flex;\n    flex-direction: column;\n\n    align-items: center;\n    justify-content: flex-end;\n    z-index: 100;\n\n}\n\n.decor{\n    content: \"\";\n    color: #fff;\n    display: block;\n    position: absolute;\n    border: solid 4px rgba(210, 206, 196, .5);\n    \n    width: 56%;\n    height: 60%;\n\n    bottom: 28%;\n    right: 32%; \n    pointer-events: none;\n    z-index: -1;\n}\n\n\n\n.containerFlipped{\n    margin-top: auto;\n    margin-bottom: 0;\n\n    justify-content: flex-start;\n}\n\n.decorFliped{\n    bottom: 0;\n    border: solid 4px rgba(255, 255, 0);\n}\n\n.mainContainer{\n    display: flex;\n    height: 100vh;\n    gap: 86px;\n    \n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `main-form_container__7UNGW`,
	"decor": `main-form_decor__qp3l7`,
	"containerFlipped": `main-form_containerFlipped__dF8mD`,
	"decorFliped": `main-form_decorFliped__NOAs3`,
	"mainContainer": `main-form_mainContainer__7chIW`
};
export default ___CSS_LOADER_EXPORT___;
