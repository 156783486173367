import React, { createContext, ReactNode } from 'react';

import GameJoinPlayersCount from '../components/game-join/game-join-players-count/players-count';
import RegistrateTeamForm from '../components/game-join/registrate-team-form/registrate-team';
import FindGame from '../components/game-join/join-game/find-game';
import User from '../constants/user';
import WaitRoom from '../components/game-join/wait-room/wait-room';
import { Tour } from '../constants/game';

interface IForm {
  prev: ReactNode | null;
  current: ReactNode;
  next: ReactNode;
  onSubmit: () => Promise<any> | any;
}

interface IForms {
  [key: string]: IForm;
}

interface IFormsContext {
  innerForm: string;
  setInnerForm: (x: string) => void;
  fliped: boolean;
  setFliped: (x: boolean) => void;
  isReady: boolean;
  setIsReady: (x: boolean) => void;
  forms: IForms;
  user: User;
  setUser: (x: User) => void;
  jwtToken: string;
  setJwtToken: (x: string) => void;
  tours: Tour[];
  setTours: (x: Tour[]) => void;
  currentTeamId: string;
  setCurrentTeamId: (x: string) => void;
  privateCode: string;
  setPrivateCode: (x: string) => void;
  teamName: string,
  setTeamName: (x: string) => void,
  currentTour: string,
  setCurrentTour: (x: string) => void,
  currentGameState: string,
  setCurrentGameState: (x: string) => void,
  currentQuestion: number,
  setCurrentQuestion: (x: number) => void,


}

export const defaultState: IFormsContext = {

  currentQuestion: 0,
  setCurrentQuestion: (x: number) => {},


  innerForm: '',
  setInnerForm: () => {},

  tours: [new Tour()],
  setTours: (x: Tour[]) => {},

  fliped: false,
  setFliped: () => {},

  isReady: false,
  setIsReady: (x: boolean) => {},

  user: new User('', '', '', '', ''),
  setUser: (x: User) => {},

  privateCode: '',
  setPrivateCode: (x: string) => {},

  jwtToken: '',
  setJwtToken: (x: string) => {},

  currentTeamId: '',
  setCurrentTeamId: () => {},

  teamName: '',
  setTeamName: (x: string) => {},

  currentTour: '',
  setCurrentTour: (x: string) => {},

  currentGameState: '',
  setCurrentGameState: (x: string) => {},

  forms: {
    'findGame': {
      prev: '',
      current: <FindGame />,
      next: '',
      onSubmit: async () => {
        // Пример возврата значения
        return true;
      },
    },
    'playersCount': {
      prev: '',
      current: <GameJoinPlayersCount />,
      next: 'registrateTeam',
      onSubmit: async () => {

        return false;
      },
    },
    'registrateTeam': {
      prev: 'playersCount',
      current: <RegistrateTeamForm />,
      next: 'selectThemes',
      onSubmit: async () => {

        return false;
      },
    },
    'selectThemes': {
      prev: 'registrateTeam',
      current: <></>,
      next: "waitRoom",
      onSubmit: async () => {
        return false;
      },
    },
    'waitRoom': {
      prev: '',
      current: <WaitRoom />,
      next: null,
      onSubmit: async () => {

        return false;
      },
    },
  },
};

export const FormsContext = createContext<IFormsContext>(defaultState);
