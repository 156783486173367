import { ButtonHTMLAttributes } from "react";
import classes from "./button.module.css"


interface BeginGameButtonProps extends ButtonHTMLAttributes<HTMLButtonElement>{
    text: string,
}



const BeginGameButton: React.FC<BeginGameButtonProps> = (props) => {

    return ( 
        <button {...props} className={classes.button} >
            <p className={classes.text}>{props.text}</p>
        </button>
     );
}
 
export default BeginGameButton;