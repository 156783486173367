import React from "react";
import cl from "./error.module.css"

interface iCustomError{
    errorText: string,
    fs?: number
}

const CustomError: React.FC<iCustomError> = ({errorText, fs}) => {
    return ( 
    <div className={cl.container}>
        <p style={{fontSize: `${fs}px`}} className={cl.text}>{errorText}</p>
    </div> 
    );
}
 
export default CustomError;