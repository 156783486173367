import { InputHTMLAttributes } from "react";
import cl from './choose-variant.module.css'

interface iChooseVariantBtn extends InputHTMLAttributes<HTMLInputElement> {
    text: string;
  }
  

const ChooseVariantBtn: React.FC<iChooseVariantBtn> = (props) => {
    return (
        <div className={cl.container}>
            <input
                type={'radio'}
                id={props.id}
                name={props.name}
                value={props.value}
                checked={props.checked}
                onChange={props.onChange}
                className={cl.radio}
            />        
            <label className={cl.label} htmlFor={props.id}>
                {props.text}
            </label>
        </div>)}
 
export default ChooseVariantBtn;