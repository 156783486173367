// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-name_nickname__nxPG- {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    color: #fff;
    font-size: 24px;
}

.user-name_nickContainer__hY1uR {
    position: relative; 
    background-color: #3F3E3C;
    margin-top: auto;
    padding: 17px;
    padding-bottom: 75px;
    z-index: 1;
    margin-left: 57px;
    transition: transform .3s ease-in; 
}

.user-name_nickContainer__hY1uR:hover {
    transform: translateY(20px);
    cursor: pointer;
}

.user-name_nickContainerFliped__if3dT{
    position: relative; 
    background-color: #3F3E3C;
    margin-top: auto;
    padding: 17px;
    padding-bottom: 75px;
    z-index: 1;
    margin-left: 57px;
    transition: transform .3s ease-in; 
}

.user-name_nickContainerFliped__if3dT:hover{
    transform: translateY(-20px);
    cursor: pointer;
}


.user-name_mainContainer__EugBG {
    display: flex;
}

.user-name_fliped__AN-wG {
    margin-bottom: auto;
    margin-top: 0;
}


`, "",{"version":3,"sources":["webpack://./src/components/game-menu/user-name/user-name.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,yBAAyB;IACzB,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,gBAAgB;IAChB,aAAa;IACb,oBAAoB;IACpB,UAAU;IACV,iBAAiB;IACjB,iCAAiC;AACrC;;AAEA;IACI,2BAA2B;IAC3B,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,gBAAgB;IAChB,aAAa;IACb,oBAAoB;IACpB,UAAU;IACV,iBAAiB;IACjB,iCAAiC;AACrC;;AAEA;IACI,4BAA4B;IAC5B,eAAe;AACnB;;;AAGA;IACI,aAAa;AACjB;;AAEA;IACI,mBAAmB;IACnB,aAAa;AACjB","sourcesContent":[".nickname {\n    writing-mode: vertical-rl;\n    transform: rotate(180deg);\n    color: #fff;\n    font-size: 24px;\n}\n\n.nickContainer {\n    position: relative; \n    background-color: #3F3E3C;\n    margin-top: auto;\n    padding: 17px;\n    padding-bottom: 75px;\n    z-index: 1;\n    margin-left: 57px;\n    transition: transform .3s ease-in; \n}\n\n.nickContainer:hover {\n    transform: translateY(20px);\n    cursor: pointer;\n}\n\n.nickContainerFliped{\n    position: relative; \n    background-color: #3F3E3C;\n    margin-top: auto;\n    padding: 17px;\n    padding-bottom: 75px;\n    z-index: 1;\n    margin-left: 57px;\n    transition: transform .3s ease-in; \n}\n\n.nickContainerFliped:hover{\n    transform: translateY(-20px);\n    cursor: pointer;\n}\n\n\n.mainContainer {\n    display: flex;\n}\n\n.fliped {\n    margin-bottom: auto;\n    margin-top: 0;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nickname": `user-name_nickname__nxPG-`,
	"nickContainer": `user-name_nickContainer__hY1uR`,
	"nickContainerFliped": `user-name_nickContainerFliped__if3dT`,
	"mainContainer": `user-name_mainContainer__EugBG`,
	"fliped": `user-name_fliped__AN-wG`
};
export default ___CSS_LOADER_EXPORT___;
