// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.answer_header__hUAUS{
    margin-left: 46%;
    display: flex;
    flex-direction: row;
    margin-right: auto;
    justify-content: center;
}


.answer_title__ovOqv{
    font-family: 'Kyiv Type Sans';
    font-size: 64px;

    margin-right: auto;

    margin-top: 75px;
    margin-bottom: 64px;
}

.answer_mainContainer__PvGGs{
    width: 100%;
    height: 100%;

    overflow-y: scroll;
}

.answer_questions__CZzbl{
    display: flex;
    flex-direction: column;
    gap: 106px;
}

@media (max-width: 1024px){
    .answer_title__ovOqv{
        font-size: 42px;
    }
    .answer_questions__CZzbl{
        font-size: 32px;
    }
    
}`, "",{"version":3,"sources":["webpack://./src/components/game-process/tour-answer/answer.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,uBAAuB;AAC3B;;;AAGA;IACI,6BAA6B;IAC7B,eAAe;;IAEf,kBAAkB;;IAElB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;;IAEZ,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,UAAU;AACd;;AAEA;IACI;QACI,eAAe;IACnB;IACA;QACI,eAAe;IACnB;;AAEJ","sourcesContent":[".header{\n    margin-left: 46%;\n    display: flex;\n    flex-direction: row;\n    margin-right: auto;\n    justify-content: center;\n}\n\n\n.title{\n    font-family: 'Kyiv Type Sans';\n    font-size: 64px;\n\n    margin-right: auto;\n\n    margin-top: 75px;\n    margin-bottom: 64px;\n}\n\n.mainContainer{\n    width: 100%;\n    height: 100%;\n\n    overflow-y: scroll;\n}\n\n.questions{\n    display: flex;\n    flex-direction: column;\n    gap: 106px;\n}\n\n@media (max-width: 1024px){\n    .title{\n        font-size: 42px;\n    }\n    .questions{\n        font-size: 32px;\n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `answer_header__hUAUS`,
	"title": `answer_title__ovOqv`,
	"mainContainer": `answer_mainContainer__PvGGs`,
	"questions": `answer_questions__CZzbl`
};
export default ___CSS_LOADER_EXPORT___;
