// ConfirmationModal.tsx
import React, { FC } from 'react';
import classes from './confirm.module.css';

interface ConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  message: string;
}

const ConfirmationModal: FC<ConfirmationModalProps> = ({ isOpen, onClose, onConfirm, message }) => {
  if (!isOpen) return null;

  return (
    <div className={classes.modalOverlay}>
      <div className={classes.modal}>
        <p className={classes.message}>{message}</p>
        <div className={classes.buttons}>
          <button className={classes.button} onClick={onConfirm}>Да</button>
          <button className={classes.button} onClick={onClose}>Нет</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
