import { useContext, useState, useEffect, useRef } from "react";
import BeginGameButton from "../../buttons/begin-game/button";
import classes from './form.module.css';
import { FormsContext } from "../../../context/context";
import { useNavigate } from "react-router-dom";
import CustomInput from "../../buttons/input/custom-input";
import axios, { Axios, AxiosError } from "axios";
import { baseGameUrl } from "../../../constants/const";
import useShakeAnimation from "../../../utils/useShakeAnimation";
import { animated } from "@react-spring/web";
import CustomError from "../../validate/error";

const GameBeginForm = () => {
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const navigate = useNavigate();
    const popupRef = useRef<HTMLDivElement>(null);
    const [shake, setShake] = useState(false);
    const [errorName, setErrorName] = useState(false);
    const [errorCode, setErrorCode] = useState(false);

    const animate = useShakeAnimation(shake);
    
    const { setInnerForm, setFliped, user, setUser, setCurrentTeamId, setTeamName, teamName, setIsReady, privateCode, setPrivateCode } = useContext(FormsContext);

 
    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                setPrivateCode('');
                setIsPopupVisible(false);
            }
        };

        const handleClickOutside = (e: MouseEvent) => {
            if (popupRef.current && !popupRef.current.contains(e.target as Node)) {
                setPrivateCode('');
                setIsPopupVisible(false);
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setPrivateCode]);


    useEffect(()=>{
        setFliped(false);
        setInnerForm('');
        setIsReady(false);
    })

    const onCreateClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setInnerForm('playersCount');
        navigate('playersCountId');
    };

    const onJoinClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setInnerForm('findGame');
        setFliped(true);
        navigate('findGame');
    };

    const onJoinPrivateClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setIsPopupVisible(true);
    };

    const handlePrivateCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setPrivateCode(value ? value : '');
    };

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setTeamName(value);
    };


    const handlePopupSubmit = async () => {
        try {
            const response = await axios.post(`${baseGameUrl}add-team-with-private-code`, {
                teamName,
                userId: user._id,
                privateCode,
            });
            setUser({...user, gameId: response.data.gameId});
            setCurrentTeamId(response.data.team._id);
            setTeamName(teamName);

        } catch (e) {
            setShake(true);
            setTimeout(() => {setShake(false); setErrorCode(false); setErrorName(false)}, 2000);
            if (axios.isAxiosError(e))  {
                if(e.response?.status === 400){
                    setErrorName(true);
                }else{
                    setErrorCode(true);
                }
              } 
        }
    }

    const closePopup = () => {
        setIsPopupVisible(false);
    };

    return (
        <form className={classes.container}>
            <BeginGameButton text='Создать Игру' onClick={onCreateClick}></BeginGameButton>
            <BeginGameButton text='Присоединиться к игре' onClick={onJoinClick}></BeginGameButton>
            <BeginGameButton text='Присоединиться к приватной игре' onClick={onJoinPrivateClick}></BeginGameButton>
            {isPopupVisible && (
                <div className={classes.popup__container}>
                    <animated.div  style={animate} className={classes.popup__content} ref={popupRef}>

                    {
                        errorCode ? 
                            <CustomError errorText="Не удалось найти игру с таким кодом "/> :
                            ''
                    }             
                    {       
                        errorName ? 
                            <CustomError errorText="Такое имя команды уже занято"/> :
                            ''
                    }
                    <CustomInput
                            label='Название команды'
                            placeholder='Примерчик'
                            value={teamName}
                            name="teamName"
                            type="text"
                            addClass={true}
                            onChange={handleNameChange}
                        />


                        <CustomInput
                            label='Секретный код'
                            placeholder='****'
                            value={privateCode}
                            name="code"
                            type="text"
                            addClass={true}
                            onChange={handlePrivateCodeChange}
                        />

                        <button className={classes.submit__popup} onClick={handlePopupSubmit} type="button" >Присоединиться</button>
                    </animated.div>
                </div>
            )}
        </form>
    );
};

export default GameBeginForm;
