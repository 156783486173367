import { useContext, useEffect, useState } from "react";
import classes from './players-count.module.css'
import { FormsContext, defaultState } from "../../../context/context";
import React from "react";
import RadioButton from "../../buttons/radio-checkbox/button";
import { baseGameUrl, playersCount } from "../../../constants/const";
import { game } from "../../../App";
import { UseLocalStorage } from "../../../context/useLocalStorage";

import axios from"axios";
import useShakeAnimation from "../../../utils/useShakeAnimation";
import { animated } from "@react-spring/web";
import CustomError from "../../validate/error";



const GameJoinPlayersCount = () => {

    
    const [selectedPlayerCount, setSelectedPlayerCount] = UseLocalStorage('playersCount', '2');
    const {setFliped, setIsReady, innerForm, setInnerForm, user, setUser, setPrivateCode} = useContext(FormsContext);
    const [isPrivateGame, setIsPrivateGame] = useState(false);
    const [shake, setShake] = useState(false);

    const animate = useShakeAnimation(shake);


    useEffect(() => {
        setFliped(true);
        setIsReady(true);

        defaultState.forms[innerForm].onSubmit = handleSubmit;
    },);

    const handleSubmit = async () => {
        try{
        const response = await axios.post(`${baseGameUrl}create-game`, {"teamsCount": Number(selectedPlayerCount), "userId": user._id, "isPrivate": isPrivateGame})
        console.log(response.data);

        const updatedUser = { ...user, gameId: response.data.game._id };
        
        setUser(updatedUser);
        if(response.data.privateCode){
            setPrivateCode(response.data.privateCode);
        }else{
            setPrivateCode('');
        }
        
        return(response.data.game._id);
        } catch(error) {
            setPrivateCode('');
            setShake(true);
            setTimeout(() => setShake(false), 2000);
            return false;
        }
    }

    const handlePlayerCountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsReady(true);
        setSelectedPlayerCount(event.target.value);

        
    };
    


    const onRadioChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        if(evt.target.value){
            setIsPrivateGame(evt.target.checked);
            
            return;
        }
    }
    return (
        <form onSubmit={handleSubmit} >
        
        <animated.div style={animate} className={classes.form}>
            {
                shake ?
                <CustomError errorText="Ошибка сервера, попробуйте создать игру позже или выйти из аккаунта"/> :
                <>
                    <h2 className={classes.title}>Количество команд/игроков:</h2>
                    <ul className={classes.radioList}>
                        {playersCount.map((count) => (
                        <li className={classes.radioListItem} key={count}>
                            <RadioButton 
                                type="radio"
                                id={`player-count-${count}`}
                                name="player-count"
                                value={count}
                                checked={Number(selectedPlayerCount) === count}
                                onChange={handlePlayerCountChange}
                                text={String(count)}
                            />
                        </li>
                        ))}
                    </ul>
                    <div className={classes.radioContainer}>
                        <RadioButton
                            type={'checkbox'}
                            id={'private-game'}
                            name={'private-game'}
                            value={'false'}
                            checked={isPrivateGame}
                            onChange={onRadioChange}
                            text={'сделать игру приватной'}
                        />
                    </div>
                
                </>
            }
        </animated.div>
      
        </form>
    );
};

export default GameJoinPlayersCount;