import cl from './tour-results.module.css'
import ResultsTable from "./results-table/table";
import GameHint from '../../game-menu/hint/game-hint';
import { useContext, useEffect, useState } from 'react';
import { iTour } from '../../../constants/game';
import { useNavigate } from 'react-router-dom';
import { FormsContext } from '../../../context/context';
import { baseGameUrl } from '../../../constants/const';
import axios from 'axios';
const lamp = require('../../../assets/img/HintLamp_smalll.png')

const TourResults = (tour: iTour) => {

    const time = 15 * 1000

    const [finishTime, setFinishTime] = useState(new Date().getTime() + time);
    const [[diffM, diffS], setDiff] = useState([ 0, 0]);
    const [tick, setTick] = useState(false);
    const [isFinal, setIsFinal] = useState(false);
    const {currentTeamId, tours, setCurrentTour, setCurrentGameState, setInnerForm ,setCurrentQuestion} = useContext(FormsContext);

    const nav = useNavigate()

    useEffect(()=> {

        const resetState = async () => {
            try{
                const resp = axios.post(`${baseGameUrl}reset-submit-results`, {teamId: currentTeamId})
            }catch{
                console.log('ОШИБКА ОШИБКА');
            }
        }

        const diff = (finishTime - new Date().getTime()) / 1000;
        if (diff < 0 && tour) {
          if(tour.sequence === 7){
            localStorage.setItem("currentTour", '');
            setInnerForm('results');
            setIsFinal(true);
            return;
          }
        
          resetState();
          setCurrentTour(tours[tour.sequence].tourId);
          setCurrentGameState('tour');
          setCurrentQuestion(0);
          nav(`/tourId${tours[tour.sequence].tourId}`);
          return
        } 
    
        setDiff([
          Math.floor((diff / 60) % 60), 
          Math.floor(diff % 60),
        ]) 
    
    }, [tick, finishTime, currentTeamId, nav, setCurrentGameState, tour, setCurrentQuestion, tours, setCurrentTour])

    useEffect(()=>{
        const timerID = setInterval(() => setTick(!tick), 1000);
        return () => clearInterval(timerID);
    }, [tick])



    return ( 
    <div className={cl.container}>
        <div className={cl.header}>
        <div className={cl.title_container}>
         {!isFinal ? (
            <>
                <h1 className={cl.title}>Тур {tour.sequence}</h1>
                <p className={cl.text}>Результаты</p>
            </>
            ) : (
                <h1 className={cl.title}>Итоги</h1>
            )}
        </div>

            <img className={cl.hint} src={lamp} alt="" />
        </div>
       <ResultsTable {...tour} isFinal={isFinal}/>
    </div> 
    );
}
 
export default TourResults;