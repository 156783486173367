// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.registrate-team_container__JxfqT{
    display: flex;
    flex-direction: column;
    padding: 51px;
    gap: 46px;

    width: 100%;

}


.registrate-team_title__lP6D2{
    font-family: 'Jura';
    font-size: 32px;
    color: #fff;
    font-weight: normal;
    max-width: 400px;
}

.registrate-team_input__NqTll {
    height: 50px;
    background-color: transparent;
    outline: 2px solid #f7df00;
    color: #fff;
    text-shadow: 0 0 1px #000;
    font-size: 24px;
    font-family: 'Jura';
    transition: background .3s ease-out;
    padding-left: 10px;
  }
  
.registrate-team_input__NqTll:focus{
    background: rgba(247, 223, 0, 0.15);
}

.registrate-team_inputActive__aAzOy{
    background: rgba(247, 223, 0, 0.15);
}


.registrate-team_radioContainer__eKzpH{
    display: flex;
    gap: 15px;
}

.registrate-team_game_mode__V-lft{
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-bottom: 40px;

}

.registrate-team_game_mode__V-lft p{
    color:#fff;
    font-family: 'Jura';
    font-size: 40px;
    margin-bottom: 40px;
}

.registrate-team_game__mode_buttons__TZg7j{
    width: 100%;
    display: flex;
    justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/components/game-join/registrate-team-form/registrate-team.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,aAAa;IACb,SAAS;;IAET,WAAW;;AAEf;;;AAGA;IACI,mBAAmB;IACnB,eAAe;IACf,WAAW;IACX,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,6BAA6B;IAC7B,0BAA0B;IAC1B,WAAW;IACX,yBAAyB;IACzB,eAAe;IACf,mBAAmB;IACnB,mCAAmC;IACnC,kBAAkB;EACpB;;AAEF;IACI,mCAAmC;AACvC;;AAEA;IACI,mCAAmC;AACvC;;;AAGA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;;IAEnB,mBAAmB;;AAEvB;;AAEA;IACI,UAAU;IACV,mBAAmB;IACnB,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,8BAA8B;AAClC","sourcesContent":[".container{\n    display: flex;\n    flex-direction: column;\n    padding: 51px;\n    gap: 46px;\n\n    width: 100%;\n\n}\n\n\n.title{\n    font-family: 'Jura';\n    font-size: 32px;\n    color: #fff;\n    font-weight: normal;\n    max-width: 400px;\n}\n\n.input {\n    height: 50px;\n    background-color: transparent;\n    outline: 2px solid #f7df00;\n    color: #fff;\n    text-shadow: 0 0 1px #000;\n    font-size: 24px;\n    font-family: 'Jura';\n    transition: background .3s ease-out;\n    padding-left: 10px;\n  }\n  \n.input:focus{\n    background: rgba(247, 223, 0, 0.15);\n}\n\n.inputActive{\n    background: rgba(247, 223, 0, 0.15);\n}\n\n\n.radioContainer{\n    display: flex;\n    gap: 15px;\n}\n\n.game_mode{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n\n    margin-bottom: 40px;\n\n}\n\n.game_mode p{\n    color:#fff;\n    font-family: 'Jura';\n    font-size: 40px;\n    margin-bottom: 40px;\n}\n\n.game__mode_buttons{\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `registrate-team_container__JxfqT`,
	"title": `registrate-team_title__lP6D2`,
	"input": `registrate-team_input__NqTll`,
	"inputActive": `registrate-team_inputActive__aAzOy`,
	"radioContainer": `registrate-team_radioContainer__eKzpH`,
	"game_mode": `registrate-team_game_mode__V-lft`,
	"game__mode_buttons": `registrate-team_game__mode_buttons__TZg7j`
};
export default ___CSS_LOADER_EXPORT___;
