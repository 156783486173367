// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timer_container__Xg\\+u3{
    margin-top: 44px;
    margin-right: 52px;

    margin-left: auto;
}

.timer_text__UZJLo{
    font-family: 'Jura';
    font-size: 54px;
    font-weight: 700;
    margin-right: 18px;

}

.timer_ico__en9Qn{

}


.timer_coming__Nye4M{
    color: #FC5140;
}

@media(max-width: 1024px){
    .timer_text__UZJLo{
        font-size: 32px;
    }
    .timer_img__Ky9p2{
        height: 40px;

    }
}`, "",{"version":3,"sources":["webpack://./src/components/game-process/timer/timer.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,kBAAkB;;IAElB,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,kBAAkB;;AAEtB;;AAEA;;AAEA;;;AAGA;IACI,cAAc;AAClB;;AAEA;IACI;QACI,eAAe;IACnB;IACA;QACI,YAAY;;IAEhB;AACJ","sourcesContent":[".container{\n    margin-top: 44px;\n    margin-right: 52px;\n\n    margin-left: auto;\n}\n\n.text{\n    font-family: 'Jura';\n    font-size: 54px;\n    font-weight: 700;\n    margin-right: 18px;\n\n}\n\n.ico{\n\n}\n\n\n.coming{\n    color: #FC5140;\n}\n\n@media(max-width: 1024px){\n    .text{\n        font-size: 32px;\n    }\n    .img{\n        height: 40px;\n\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `timer_container__Xg+u3`,
	"text": `timer_text__UZJLo`,
	"ico": `timer_ico__en9Qn`,
	"coming": `timer_coming__Nye4M`,
	"img": `timer_img__Ky9p2`
};
export default ___CSS_LOADER_EXPORT___;
