// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.show-question_container__K852K{
    overflow-y: scroll;

    display: flex;
    flex-direction: column;
    height: 100vh;
}

.show-question_title__cE2FY{
    font-family: 'Kyiv Type Sans';
    font-size: 64px;
    margin-right: auto;

    margin-top: 75px;


    display: flex;
    flex-direction: column;
    align-items: center;
}

.show-question_header__zyitC{

    margin-left: 40%;
    display: flex;
    flex-direction: row;

    justify-content: center;

}


.show-question_question__value__qos5u{
    font-family: 'Jura';
    font-weight: 600;
}

.show-question_question__question__TNzjQ{
    display:flex;
    justify-content: center;



    text-align: center;
    font-size: 64px;
    font-family: 'Jura';
    padding: 3% 1%;


    width: 100%;
}
.show-question_variants__UMc3j{
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: start;
    text-align: left;

    row-gap: 50px;

    font-family: 'Jura';
    font-size: 48px;
    overflow-x: none;
    font-weight: 700;

    background-color: rgba(247, 223, 0, .2);
    padding: 50px 10%;
}



@media(max-width:1024px){
    .show-question_variants__UMc3j{
        font-size: 32px;
    }
    .show-question_title__cE2FY{
        font-size: 52px;
    }
    .show-question_question__question__TNzjQ{
        font-size: 32px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/game-process/tour-show/show-question.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;;IAElB,aAAa;IACb,sBAAsB;IACtB,aAAa;AACjB;;AAEA;IACI,6BAA6B;IAC7B,eAAe;IACf,kBAAkB;;IAElB,gBAAgB;;;IAGhB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;;IAEI,gBAAgB;IAChB,aAAa;IACb,mBAAmB;;IAEnB,uBAAuB;;AAE3B;;;AAGA;IACI,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,uBAAuB;;;;IAIvB,kBAAkB;IAClB,eAAe;IACf,mBAAmB;IACnB,cAAc;;;IAGd,WAAW;AACf;AACA;IACI,aAAa;IACb,8BAA8B;IAC9B,oBAAoB;IACpB,gBAAgB;;IAEhB,aAAa;;IAEb,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,gBAAgB;;IAEhB,uCAAuC;IACvC,iBAAiB;AACrB;;;;AAIA;IACI;QACI,eAAe;IACnB;IACA;QACI,eAAe;IACnB;IACA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".container{\n    overflow-y: scroll;\n\n    display: flex;\n    flex-direction: column;\n    height: 100vh;\n}\n\n.title{\n    font-family: 'Kyiv Type Sans';\n    font-size: 64px;\n    margin-right: auto;\n\n    margin-top: 75px;\n\n\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.header{\n\n    margin-left: 40%;\n    display: flex;\n    flex-direction: row;\n\n    justify-content: center;\n\n}\n\n\n.question__value{\n    font-family: 'Jura';\n    font-weight: 600;\n}\n\n.question__question{\n    display:flex;\n    justify-content: center;\n\n\n\n    text-align: center;\n    font-size: 64px;\n    font-family: 'Jura';\n    padding: 3% 1%;\n\n\n    width: 100%;\n}\n.variants{\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    justify-items: start;\n    text-align: left;\n\n    row-gap: 50px;\n\n    font-family: 'Jura';\n    font-size: 48px;\n    overflow-x: none;\n    font-weight: 700;\n\n    background-color: rgba(247, 223, 0, .2);\n    padding: 50px 10%;\n}\n\n\n\n@media(max-width:1024px){\n    .variants{\n        font-size: 32px;\n    }\n    .title{\n        font-size: 52px;\n    }\n    .question__question{\n        font-size: 32px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `show-question_container__K852K`,
	"title": `show-question_title__cE2FY`,
	"header": `show-question_header__zyitC`,
	"question__value": `show-question_question__value__qos5u`,
	"question__question": `show-question_question__question__TNzjQ`,
	"variants": `show-question_variants__UMc3j`
};
export default ___CSS_LOADER_EXPORT___;
