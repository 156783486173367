import React, { useState } from 'react';
import HelpButton from "../../buttons/help/help-button";
import GameRulesPopup from './rules/rules';
import classes from "./game-hint.module.css";
const lamp = require('../../../assets/img/HintLamp.png');

const GameHint: React.FC = () => {
    const [showPopup, setShowPopup] = useState(false);

    const handleHelpButtonClick = () => {
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    return (
        <div className={classes.container}>
            <img className={classes.img} src={lamp} alt="" />
            <div className={classes.buttonsСontainer}>
                <HelpButton text={'Правила игры'} onClick={handleHelpButtonClick} />
            </div>
            {showPopup && <GameRulesPopup onClose={handleClosePopup} />}
        </div>
    );
};

export default GameHint;
