import { useContext, useEffect, useState } from 'react';
import NextFormButton from '../../buttons/next-form/button';
import PrevFormButton from '../../buttons/prev-form/button';
import CustomSearch from '../../search/search';
import cl from './select-themes.module.css';
import { FormsContext } from '../../../context/context';
import { useNavigate } from 'react-router-dom';
import { Themes, baseGameUrl } from '../../../constants/const';
import GameHint from '../../game-menu/hint/game-hint';
import CustomCheckbox from '../../buttons/themes-checkox/check-box';
import { defaultState } from '../../../context/context';
import { UseLocalStorage } from '../../../context/useLocalStorage';
import axios from 'axios';
import useShakeAnimation from '../../../utils/useShakeAnimation';
import { animated } from '@react-spring/web';
import CustomError from '../../validate/error';

const SelectThemes = () => {
    const [choosenThemes, setChoosenThemes] = UseLocalStorage<string[]>('selectedThemes', []);

    const [shake, setShake] = useState(false);
    const animate = useShakeAnimation(shake);

    const navigate = useNavigate();
    const { setInnerForm, setFliped, forms, innerForm, setIsReady, fliped, isReady, user, setTours } = useContext(FormsContext);

    useEffect(() => {
        defaultState.forms[innerForm].onSubmit = handleSubmit;
        setIsReady(choosenThemes.length > 2);
    });

    const handleSubmit = async () => {
        try {
            const response = await axios.post(`${baseGameUrl}update-game-with-themes`, {
                gameId: user.gameId,
                themes: choosenThemes
            });
            setTours(response.data);
            return response;
        } catch (e) {
            setShake(true)
            setTimeout(() => setShake(false), 2000);
            console.error(e);
        }
    };

    const onNextButtonClick = async () => {
        const currentForm = forms[innerForm];
        const response = await currentForm.onSubmit();
        if (response != null) {
            if (currentForm.next && typeof currentForm.next === 'string') {
                setInnerForm(currentForm.next);
                navigate(`/${currentForm.next}Id${user.gameId}`);
                setIsReady(false);
                setFliped(!fliped);
            }
        }
    };

    const onPrevButtonClick = () => {
        const currentForm = forms[innerForm];
        if (currentForm.prev && typeof currentForm.prev === 'string') {
            setInnerForm(currentForm.prev);
            navigate(`/main/${currentForm.prev}Id${user.gameId}`);
            setFliped(!fliped);
        }
    };

    const onCheckboxClick = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = evt.target;
        const id = evt.target.id.split('-')[1];
        let updatedThemes;

        if (checked) {
            updatedThemes = [...choosenThemes, id];
        } else {
            updatedThemes = choosenThemes.filter((option) => option !== id);
        }


        setChoosenThemes(updatedThemes);
        setIsReady(updatedThemes.length > 2);
    };

    return (
        <div className={cl.container}>
            <aside className={cl.aside}>
                <div className={cl.content}>
                    <CustomSearch values={Themes} choosenThemes={choosenThemes} onChange={onCheckboxClick} />
                </div>
                <GameHint />
            </aside>
            <form className={cl.form} action="">
                        <h1>Выберите темы (Не меньше 3-х):</h1>
                        <animated.div style={animate}>
                            {
                                shake ? 
                                <CustomError errorText='Ошибка при отправке тем' /> :
                                    <ul className={cl.buttons}>
                                    {Themes.map((value) => (
                                        <li key={value}>
                                            <CustomCheckbox
                                                text={value}
                                                checked={choosenThemes.includes(value)}
                                                onChange={onCheckboxClick}
                                                keyValue={value}
                                                id={'theme-' + value}
                                            />
                                        </li>
                                    ))}
                                </ul>
                            }
                        </animated.div>
                    </form>
            <div className={cl.choosen}>
                    <p>Выбранные темы: </p>
                    <ul>
                        {choosenThemes.map((value, index) => (
                            <li key={value}>{value}{index !== choosenThemes.length - 1 ? ',' : ''}</li>
                        ))}
                    </ul>
                </div>
            <PrevFormButton isThemeForm={true} onClick={onPrevButtonClick} />
            {isReady && <NextFormButton isThemeForm={true} onClick={onNextButtonClick} />}
            <div className={cl.decoration}></div>
        </div>
    );
};

export default SelectThemes;
