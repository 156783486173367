import { useContext, useEffect, useState } from 'react';
import Username from '../../game-menu/user-name/user-name';
import cl from './find-game.module.css';
import { FormsContext } from '../../../context/context';
import PrevFormButton from '../../buttons/prev-form/button';
import { useNavigate } from 'react-router-dom';
import GameHint from '../../game-menu/hint/game-hint';
import axios from 'axios';
import { baseGameUrl } from '../../../constants/const';
import { GameSession } from '../../../constants/game';
import CustomInput from '../../buttons/input/custom-input';
import useShakeAnimation from '../../../utils/useShakeAnimation';
import { animated } from '@react-spring/web';
import CustomError from '../../validate/error';
import { loadavg as loading } from 'os';

const FindGame = () => {
    const [allGames, setAllGames] = useState<GameSession[]>([]);
    const [selectedGameId, setSelectedGameId] = useState<string>('');
    const { setUser, user, innerForm, setInnerForm, forms, setFliped, fliped, setCurrentTeamId, setTeamName, teamName } = useContext(FormsContext);
    const navigate = useNavigate();
    const headers = ['Автор комнаты', 'Режим', 'Тематика', 'Число участников'];
    const [shake, setShake] = useState(false);
    const [shakeGet, setShakeGet] = useState(false);
    const [loading, setLoading] = useState(true);

    const [error, setError] = useState<string>('');

    const animate = useShakeAnimation(shake);
    const animateGet = useShakeAnimation(shakeGet);

    const fetchGames = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${baseGameUrl}get-all-games`);

            const filteredData = response.data.filter((game: GameSession) => game.initiatorTeamName);
            setAllGames(filteredData);
            setError('');
            setSelectedGameId('');
            

        } catch (error) {
            console.log(error);
            setAllGames([]);

            setShakeGet(true);


            if(axios.isAxiosError(error) && error.response?.status) {
                setError(error.response.data.error);
            }
        } finally{
            setLoading(false);
        }
    };
    

    useEffect(() => {

        setShakeGet(false);
        fetchGames();
        
        setFliped(true);
    }, [setShakeGet, setFliped, fliped, innerForm, setInnerForm, setError, setLoading]);

    const connectToGame = async (gameId: string, teamName: string, userId: string) => {
        try {
            const addTeamResponse = await axios.post(`${baseGameUrl}add-team`, { gameId, teamName, userId });
            setUser({...user, gameId: gameId})

            setCurrentTeamId(addTeamResponse.data.team._id);
            
            const gameResponse = await axios.get(`${baseGameUrl}get-game`, {
                params: {gameId: gameId}});
            console.log(gameResponse);
            
            
            navigate(`/waitRoomId${gameId}`);
        } catch (error) {
            setShake(true);
            setTimeout(() => {setShake(false); setError('')}, 3000);
            if(axios.isAxiosError(error)) {
                setError(error.response?.data.error);
            }
        }
    };

    const createGameInfo = (game: GameSession) => (
        <li key={game._id} className={cl.game__info} onClick={() => setSelectedGameId(game._id)}>
            <p>{game.initiatorTeamName ? game.initiatorTeamName : ''}</p>
            <p>{game.gameMode}</p>
            <ul>
                {game.themes.map((theme, index) => (
                    <li key={index}>
                        <p>{theme}</p>
                    </li>
                ))}
            </ul>
            <p>{game.currentTeamsCount}/{game.teamsCount}</p>
            {selectedGameId === game._id && (
                <animated.div className={cl.teamInput} style={animate}>
                    {!error ?
                    <CustomInput
                    type="text"
                    placeholder="Введите название команды"
                    value={teamName}
                    onChange={(e) => setTeamName(e.target.value)}
                    label=''
                    AddClassName='findGame'
                /> :
                <CustomError errorText={error}/>
                }
                    
                    <button onClick={() => connectToGame(game._id, teamName, user._id)}>Присоединиться</button>
                </animated.div>
            )}
        </li>
    );

    const onPrevButtonClick = () => {
        const currentForm = forms[innerForm];

        if (currentForm.prev && typeof currentForm.prev === 'string') {
            setInnerForm(currentForm.prev);
            navigate(`/main/${currentForm.prev}`);
            setFliped(fliped ? false : true);
        } else {
            setInnerForm('');
            navigate('/');
        }
    };

    return (
        <div className={cl.container}>
            <div className={cl.header}>
                <Username nickname={user.username}/>
                <GameHint />
            </div>
            <div className={`${cl.main}`}>
                <button className={cl.reaload} onClick={fetchGames}>Обновить список</button>
                <ul className={`${cl.headers} ${cl.ul}`}>
                    {headers.map((header, index) => (
                        <li key={index} className={cl.title}>
                            {header}
                        </li>
                    ))}
                </ul>
                <ul className={cl.ul}>
                    {
                     allGames.length ? 
                        allGames.map((game) => game ? createGameInfo(game) : '') :
                            <animated.div style={animateGet} className={cl.nogames__container}>
                                {loading ? 'Загрузка игр' : <p className={cl.nogames__text}>{`${!error ? 'Созданных игр нет' : error}`}</p>}
                                
                            </animated.div>
                    }
                </ul>
            </div>
            <PrevFormButton isThemeForm={false} onClick={onPrevButtonClick} />
            <div className={cl.decoration}></div>
        </div>
    );
};

export default FindGame;
