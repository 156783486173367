// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.theme-checkbox_radio__liPfT {
    margin-right: 26px;
    display: none;

  }


  
.theme-checkbox_label__Mph7Q {
    font-size: 21px;
    font-family: 'Jura';


    font-weight: 400;
    display: block;


    padding: 5px 10px;
    border: 4px solid rgba(210, 206, 196, .5);
    font-size: 16px;

    transition: border .6s ease-out,
                background-color .3s ease-out;
}

.theme-checkbox_radio__liPfT[type="checkbox"] + label {
    color: #000;
    font-size: 24px;
}

.theme-checkbox_radio__liPfT:checked + label {
    background-color: #eaeaea;
}

.theme-checkbox_radio__liPfT:hover + label {
    
}


`, "",{"version":3,"sources":["webpack://./src/components/search/theme-checkbox/theme-checkbox.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;;EAEf;;;;AAIF;IACI,eAAe;IACf,mBAAmB;;;IAGnB,gBAAgB;IAChB,cAAc;;;IAGd,iBAAiB;IACjB,yCAAyC;IACzC,eAAe;;IAEf;6CACyC;AAC7C;;AAEA;IACI,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;;AAEA","sourcesContent":[".radio {\n    margin-right: 26px;\n    display: none;\n\n  }\n\n\n  \n.label {\n    font-size: 21px;\n    font-family: 'Jura';\n\n\n    font-weight: 400;\n    display: block;\n\n\n    padding: 5px 10px;\n    border: 4px solid rgba(210, 206, 196, .5);\n    font-size: 16px;\n\n    transition: border .6s ease-out,\n                background-color .3s ease-out;\n}\n\n.radio[type=\"checkbox\"] + label {\n    color: #000;\n    font-size: 24px;\n}\n\n.radio:checked + label {\n    background-color: #eaeaea;\n}\n\n.radio:hover + label {\n    \n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radio": `theme-checkbox_radio__liPfT`,
	"label": `theme-checkbox_label__Mph7Q`
};
export default ___CSS_LOADER_EXPORT___;
