import React from 'react';
import cl from './help-button.module.css'

interface HelpButtonProps {
    text: string;
    onClick: () => void;
}

const HelpButton: React.FC<HelpButtonProps> = ({ text, onClick }) => {
    return (
        <button className={cl.button} onClick={onClick}>
            <p className={cl.text}>{text}</p>
        </button>
    );
};

export default HelpButton;
