
export default class User {
    constructor(
      readonly _id: string,
      readonly username: string,
      readonly password: string,
      readonly email: string,
      public gameId: string,
    ) {}
  }
  
  const createUser = () => {
    return [
      new User('1', '', '0', 'exmp@beb.ru', ''),
    ];
  };

const user = createUser();

export {user}