// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.choose-variant_radio__5a3ca{
    display: none;

}
.choose-variant_label__aFDwB{
    display: block;
    padding: 22px 13px;
}

.choose-variant_label__aFDwB:hover{
    border: 2px solid rgba(210, 206, 196, .5);
    padding: 21px 12px;
    border-radius: 10px;
    cursor: pointer;
}

.choose-variant_radio__5a3ca:checked + .choose-variant_label__aFDwB{
    background: rgba(210, 206, 196, .3);
    border: 2px solid rgba(210, 206, 196, .5);
    padding: 21px 12px;
    border-radius: 10px;

}
.choose-variant_container__TtJpl{
    padding: 22px 13px;
}`, "",{"version":3,"sources":["webpack://./src/components/buttons/choose-variant/choose-variant.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;;AAEjB;AACA;IACI,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,yCAAyC;IACzC,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,mCAAmC;IACnC,yCAAyC;IACzC,kBAAkB;IAClB,mBAAmB;;AAEvB;AACA;IACI,kBAAkB;AACtB","sourcesContent":[".radio{\n    display: none;\n\n}\n.label{\n    display: block;\n    padding: 22px 13px;\n}\n\n.label:hover{\n    border: 2px solid rgba(210, 206, 196, .5);\n    padding: 21px 12px;\n    border-radius: 10px;\n    cursor: pointer;\n}\n\n.radio:checked + .label{\n    background: rgba(210, 206, 196, .3);\n    border: 2px solid rgba(210, 206, 196, .5);\n    padding: 21px 12px;\n    border-radius: 10px;\n\n}\n.container{\n    padding: 22px 13px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radio": `choose-variant_radio__5a3ca`,
	"label": `choose-variant_label__aFDwB`,
	"container": `choose-variant_container__TtJpl`
};
export default ___CSS_LOADER_EXPORT___;
