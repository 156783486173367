import { useContext, useEffect, useState } from 'react';
import classes from './registrate-team.module.css'
import { FormsContext, defaultState } from '../../../context/context';
import RadioButton from '../../buttons/radio-checkbox/button';
import { game } from '../../../App';
import { UseLocalStorage } from '../../../context/useLocalStorage';
import CustomCheckbox from '../../buttons/themes-checkox/check-box';
import axios from 'axios';
import { baseGameUrl } from '../../../constants/const';
import useShakeAnimation from '../../../utils/useShakeAnimation';
import { animated } from '@react-spring/web';
import CustomError from '../../validate/error';



const RegistrateTeamForm = () => {

    
    const [inputClass, setInputClass] = useState('');
    const [shake, setShake] = useState(false);

    const {setIsReady, user, setCurrentTeamId, teamName, setTeamName} = useContext(FormsContext);

    const shakeAnimation = useShakeAnimation(shake);

    useEffect(() => {        
       defaultState.forms['registrateTeam'].onSubmit = handleSubmit;
       if(teamName){
        setIsReady(true);
       }
    },);


    const handleSubmit = async () => {
        try {
            const response = await axios.post(`${baseGameUrl}add-team`, { 
                gameId: user.gameId, 
                teamName: teamName, 
                userId: user._id,
            });

            setCurrentTeamId(response.data.team._id);
            return user.gameId;
        } catch (e) {
            setShake(true); 
            setTimeout(() => setShake(false), 2000);  
            return false;
        }
    };
    

    const onInput = (evt: React.ChangeEvent<HTMLInputElement>) => {
        if(evt.target.value){
            setTeamName(evt.target.value)
            setIsReady(true);
            setInputClass(classes.inputActive);
        }
        else{
            setIsReady(false);
            setTeamName('');
            setInputClass('');
        }
    }



    return ( 
        <form>
            <animated.div className={classes.container} style={shakeAnimation}>
                <h2 className={classes.title}>Введите название вашей команды или ваше имя:</h2>
                <input onChange={onInput} className={`${inputClass} ${classes.input}`} value={teamName} type="text" />
                {/* <div className={classes.game_mode}>
                    <p>Выберите режим игры</p>
                    <div className={classes.game__mode_buttons}>
                        <CustomCheckbox keyValue='standart' text='Стандартный' onChange={() => {}} value={'standart'} disabled/>
                        <CustomCheckbox keyValue='theme' text='Тематический' onChange={() => {}} value={'theme-mode'} checked={true} />
                    </div>
                </div> */}
                {
                    shake ? 
                    <CustomError errorText='Такое имя команды уже занято!'/>
                    :
                    ''
                }
            </animated.div>
        </form>
     );
}
 
export default RegistrateTeamForm;