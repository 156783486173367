// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button_button__jZM09{
    background-color: transparent;
    padding: 10px 18px;

    transition: border 0.3s ease-out;
}

.button_text__0rs5r{
    color: #fff;
    font-family: 'Jura';
    font-size: 40px;

}

.button_button__jZM09:hover{
    border: 1px solid #F7df00;
    border-radius: 10px;
    cursor: pointer;
}


.button_button__jZM09:active{
    background-color: #F7df00;
    opacity: .4;
}

@media (max-width: 1440px){
    .button_text__0rs5r{
        font-size: 32px;
    }
}

@media (max-width: 1024px){
    .button_text__0rs5r{
        font-size: 24px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/buttons/begin-game/button.module.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,kBAAkB;;IAElB,gCAAgC;AACpC;;AAEA;IACI,WAAW;IACX,mBAAmB;IACnB,eAAe;;AAEnB;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,eAAe;AACnB;;;AAGA;IACI,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI;QACI,eAAe;IACnB;AACJ;;AAEA;IACI;QACI,eAAe;IACnB;AACJ","sourcesContent":[".button{\n    background-color: transparent;\n    padding: 10px 18px;\n\n    transition: border 0.3s ease-out;\n}\n\n.text{\n    color: #fff;\n    font-family: 'Jura';\n    font-size: 40px;\n\n}\n\n.button:hover{\n    border: 1px solid #F7df00;\n    border-radius: 10px;\n    cursor: pointer;\n}\n\n\n.button:active{\n    background-color: #F7df00;\n    opacity: .4;\n}\n\n@media (max-width: 1440px){\n    .text{\n        font-size: 32px;\n    }\n}\n\n@media (max-width: 1024px){\n    .text{\n        font-size: 24px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `button_button__jZM09`,
	"text": `button_text__0rs5r`
};
export default ___CSS_LOADER_EXPORT___;
