// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-input_container__4c7Ps{
    border-bottom: 1px solid #1A1A1A;
    padding-bottom: 10px;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.custom-input_input__w2wYc{
    color: #1A1A1A;
    background-color: transparent;
    text-align: right;
    max-width: 60%;
    font-family: 'Jura';
    font-size: 20px;
    padding-right: 10px;
}

.custom-input_label__YwBko{
    font-family: 'Jura';
    font-size: 20px;
    color: #1A1A1A;
}

.custom-input_addClass__-MyDa label{
    font-size: 32px;
}

.custom-input_addClass__-MyDa input{
    font-size: 32px;
}

.custom-input_findGame__qlt8k {
    font-family: 'Jura';
    font-size: 32px;
    font-weight: 700;
    padding: 10px;
  }
  
  .custom-input_findGame__qlt8k input {
    
    border: 3px solid rgb(0, 0, 0, .6);
    background-color: rgba(249, 249, 249, .6);  
    color: #3c3c3c;  
    padding: 10px 15px;  
    border-radius: 5px;  
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);  
    outline: none; 
    transition: border-color 0.3s, box-shadow 0.3s;  
  }

  .custom-input_findGame__qlt8k input::placeholder{
    color: rgba(26, 26, 26, .6)
  }
  
  .custom-input_findGame__qlt8k input:focus {
    border-color: #e0d045;  
    box-shadow: 0 0 4px rgba(255, 255, 138, 0.5); 
  }
  `, "",{"version":3,"sources":["webpack://./src/components/buttons/input/custom-input.module.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;IAChC,oBAAoB;;IAEpB,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,6BAA6B;IAC7B,iBAAiB;IACjB,cAAc;IACd,mBAAmB;IACnB,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,aAAa;EACf;;EAEA;;IAEE,kCAAkC;IAClC,yCAAyC;IACzC,cAAc;IACd,kBAAkB;IAClB,kBAAkB;IAClB,uCAAuC;IACvC,aAAa;IACb,8CAA8C;EAChD;;EAEA;IACE;EACF;;EAEA;IACE,qBAAqB;IACrB,4CAA4C;EAC9C","sourcesContent":[".container{\n    border-bottom: 1px solid #1A1A1A;\n    padding-bottom: 10px;\n\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.input{\n    color: #1A1A1A;\n    background-color: transparent;\n    text-align: right;\n    max-width: 60%;\n    font-family: 'Jura';\n    font-size: 20px;\n    padding-right: 10px;\n}\n\n.label{\n    font-family: 'Jura';\n    font-size: 20px;\n    color: #1A1A1A;\n}\n\n.addClass label{\n    font-size: 32px;\n}\n\n.addClass input{\n    font-size: 32px;\n}\n\n.findGame {\n    font-family: 'Jura';\n    font-size: 32px;\n    font-weight: 700;\n    padding: 10px;\n  }\n  \n  .findGame input {\n    \n    border: 3px solid rgb(0, 0, 0, .6);\n    background-color: rgba(249, 249, 249, .6);  \n    color: #3c3c3c;  \n    padding: 10px 15px;  \n    border-radius: 5px;  \n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);  \n    outline: none; \n    transition: border-color 0.3s, box-shadow 0.3s;  \n  }\n\n  .findGame input::placeholder{\n    color: rgba(26, 26, 26, .6)\n  }\n  \n  .findGame input:focus {\n    border-color: #e0d045;  \n    box-shadow: 0 0 4px rgba(255, 255, 138, 0.5); \n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `custom-input_container__4c7Ps`,
	"input": `custom-input_input__w2wYc`,
	"label": `custom-input_label__YwBko`,
	"addClass": `custom-input_addClass__-MyDa`,
	"findGame": `custom-input_findGame__qlt8k`
};
export default ___CSS_LOADER_EXPORT___;
