import { useContext, useEffect } from "react";
import classes from "./main-form.module.css"

import { FormsContext, defaultState } from "../../../context/context";
import NextFormButton from "../../buttons/next-form/button";

import { useNavigate } from "react-router-dom";

import PrevFormButton from "../../buttons/prev-form/button";
import { Route, Routes } from "react-router-dom";


import GameBeginForm from "../../game-join/begin-form/game-begin-form";
import Username from "../user-name/user-name";
import GameHint from "../hint/game-hint";
import { UseLocalStorage } from "../../../context/useLocalStorage";
import { user } from "../../../constants/user";
import axios from "axios";
import { baseGameUrl } from "../../../constants/const";


const MainWindow = () => {


  const navigate = useNavigate();

  const {isReady, setIsReady, setFliped ,fliped, forms, innerForm, setInnerForm, user, setUser, setPrivateCode} = useContext(FormsContext);


  const token = localStorage.getItem('token') || '';

  useEffect(() => {
    if(innerForm === '/'){
      setFliped(false);
    }
  })

  const deleteGame = async () => {
    try{
      const response = axios.delete(`${baseGameUrl}delete-game`, {
        params: {gameId : user.gameId, userId: user._id}})
      console.log(response);
      setPrivateCode('');
      setUser({...user, gameId: ''})
      
    }catch(e){
      console.log(e);
    }

  }

  const onNextButtonClick = async () => {


    const currentForm = forms[innerForm];
    
    try{
      const response = await currentForm.onSubmit();

      if(response){
        
        if (currentForm.next && typeof currentForm.next === 'string') {
  
          setInnerForm(currentForm.next);
          console.log(response);
           
          currentForm.next === 'waitRoom' || currentForm.next ==='selectThemes' ? navigate(`/${currentForm.next}Id${response}`) : navigate(`${currentForm.next}Id${response}`); 
   
          setIsReady(false);
          setFliped(fliped ? false : true);
       }
      }
    }catch(e){
      
      console.log(e);
    }
  };
   
   const onPrevButtonClick = () => {

    const currentForm = forms[innerForm];

    if(currentForm.prev === '' && user.gameId){
      
      deleteGame();

    }

    if (currentForm.prev && typeof currentForm.prev === 'string') {
        setInnerForm(currentForm.prev);
        navigate(`/main/${currentForm.prev}Id${user.gameId}`); 
        setFliped(fliped ? false : true);
    }
    else
    {
      setInnerForm('/');
      navigate('/');
    }
   };
   
  return (
    <div className={classes.mainContainer}>

      <Username nickname={user.username} />

      <div className={`${classes.container} ${fliped ? classes.containerFlipped : ''}`}>
      
      {innerForm === '' ? '' : <PrevFormButton isThemeForm={false} onClick={onPrevButtonClick} />}
      <Routes>
        <Route path="/" element={<GameBeginForm />} />
        {Object.entries(defaultState.forms).map(([key, form]) => (
          <Route
            key={key}
            path={`/${key}Id${user.gameId ? user.gameId : ''}`}
            element={form.current}
          />
        ))
        }
      </Routes>
      {isReady && !(innerForm === '/') ? <NextFormButton isThemeForm={false} onClick={onNextButtonClick} /> : null}
    </div>
    <GameHint />
    <div className={`${fliped ? classes.decorFliped : ''} ${classes.decor}`}></div>
    </div>


  );
};

export default MainWindow;
