import { useContext, useEffect, useRef, useState } from "react";
import { Tour, iTour } from "../../../constants/game";
import { Variant } from "../../../constants/game";
import { useNavigate } from "react-router-dom";
import Timer from "../timer/timer";
import { game } from "../../../App";
import cl from './show-question.module.css'
import { FormsContext } from "../../../context/context";
import { UseLocalStorage } from "../../../context/useLocalStorage";


const Question = (tour: iTour) => {

  const [currentTour, setCurrentTour] = useState(tour);

  const time = 40 * 1000 ;



  const [finishTime, setFinishTime] = useState(new Date().getTime() + time);
  const [[diffM, diffS], setDiff] = useState([ 0, 0]);
  const [tick, setTick] = useState(false);
  const {setCurrentGameState, setCurrentQuestion, currentQuestion} = useContext(FormsContext);



  const nav = useNavigate()

  useEffect(()=> {
    
    const diff = (finishTime - new Date().getTime()) / 1000;
    setCurrentGameState('tour');

    if (diff < 0 && currentTour) {
      if(currentQuestion < currentTour.questions.length - 1){
        setCurrentQuestion(currentQuestion + 1);
        setFinishTime(new Date().getTime() + time);
        return
      }else{
        setCurrentQuestion(0);
        setCurrentGameState('answer');
        nav(`/answerId${currentTour.tourId}`);
      }


    } 

    setDiff([
      Math.floor((diff / 60) % 60), 
      Math.floor(diff % 60),
    ]) 

  }, [tick, finishTime, setCurrentGameState, setCurrentQuestion, currentQuestion, currentTour, nav])

  useEffect(()=>{
    const timerID = setInterval(() => setTick(!tick), 1000);
    return () => clearInterval(timerID);
  }, [tick])

  const showVariant = (variant: Variant) => {
    return (
      <li key={variant.text}>
        {variant.text}
      </li>
    );
  };

  if (currentTour)
    
    return (
      <div className={cl.container}>
        <header className={cl.header}>
          <div className={cl.title}>
            <h1>Тур {currentTour.sequence}</h1>
            <h2 className={cl.question__value}>Вопрос {currentQuestion + 1}</h2>
          </div>
          <Timer diffM = {diffM} diffS = {diffS} />
        </header>
        <p className={cl.question__question}>
          {tour.questions[currentQuestion].content}
        </p>
        <ul className={cl.variants}>
          {currentTour.questions[currentQuestion].answers.map((variant) => {
            return showVariant(variant);
          })}
        </ul>
      </div>
    );
  return <div>ERROR</div>;
};

export default Question;
