import React from 'react';
import classes from './rules.module.css';

const closePopupImg = require('../../../../assets/img/closePopup.png');

interface GameRulesPopupProps {
    onClose: () => void;
}

const GameRulesPopup: React.FC<GameRulesPopupProps> = ({ onClose }) => {
    return (
        <div className={classes.popupOverlay}>
            <div className={classes.popupContent}>
                <img src={closePopupImg} onClick={onClose} className={classes.closeButton} alt=""/>
                <h1 className={classes.title}>Правила игры</h1>
                <ul className={classes.list}>
                    <li>
                        - В игре могут принимать участие до 6 команд или до 6 человек, которые проходят игру с разных устройств;
                    </li>
                    <li>
                        - Для начала игры Вам необходимо выбрать одну из трёх опций: создать игру (самостоятельное создание игры с подходящими параметрами), присоединиться к игре (присоединение к уже готовой игре, начатой другим игроком. Вы сможете выбрать любую интересующую игру из списка со всеми начатыми играми) или присоединиться к приватной игре (присоединение к уже готовой игре, начатой другим игроком по специальному коду);
                    </li>
                    <li>
                        - Если Вы выбрали опцию “присоединиться к игре”, то далее необходимо будет выбрать количество игроков/команд, затем указать имена игроков или названия команд. Также есть возможность сделать игру приватной (т. е. доступ к игре будет возможен только для игроков, знающих специальный код. Для каждой приватной игры создается индивидуальный код, который можно будет увидеть на странице ожидания игроков). После настройки необходимых параметров Вы попадаете на страницу ожидания игроков, где будет отображаться, какое количество игроков или команд подключились к Вашей игре. Как только подключилось необходимое количество игроков нужно нажать на кнопку “Начать игру” и игра начнется;
                    </li>
                    <li>
                        - После нажатия на кнопку “Создать игру” пользователь переходит ко 2 шагу настройки игры, прежде чем она начнется. На этом шаге игроки должны выбрать одну или несколько тем из списка (или найти интересующую тему в поиске). Игра состоит из 49 вопросов, разделенных на 7 туров.
                    </li>
                    <li>
                        В игре есть вопросы разных уровней сложности: легкие, средние и сложные. 
                    </li>
                    <li>
                        Вопросы 1-2 туров будут подбираться рандомно в соответствии с выбранными игроками темами на уровне сложности «легкий»; 3-5 туров - на уровне «средний»; 6-7 туров - на уровне «сложный».
                    </li>
                    <li>
                        Командам необходимо будет угадать термин, дополнить цитату или факт, догадаться, о каком событии идет речь, отгадать слово и т.п.
                    </li>
                    <li>
                        Вопросы всех туров показываются на экране по 40 секунд каждый, затем они все вместе показываются повторно и запускается обратный 100-секундный отсчет, по истечении которого игроки записывают ответы в специальные поля.
                    </li>
                    <li>
                        За правильный ответ на уровне сложности «легкий» дается 1 балл, на уровне «средний» - 2 балла, на уровне «сложный» - 3 балла. В случае неправильного ответа баллы не начисляются. Баллы, набранные участниками, записываются в таблицу, которая будет отображаться на экране после каждого тура;
                    </li>
                    <li>
                        - После финального раунда подводятся итоги. Победителем становится команда, набравшая наибольшее количество баллов по результатам всей игры.
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default GameRulesPopup;
