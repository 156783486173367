// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button_button__84Icw{
    position: fixed;
    top: 120px;
    left: 5%;
    top: 8%;
    background-color: transparent;
    background-color: transparent;

    transition: color .3s ease-out;
}

.button_text__GHRm7{
    font-family: 'Jura';
    font-size: 32px;
    font-weight: 400;
    line-height: 23px;
    
    transition: text-shadow .3s ease-out;
}

.button_fliped__1YY4M{
    top: 70%;
}

.button_button__84Icw:hover{
    color: #DDC800;
    cursor: pointer;
}

.button_text__GHRm7:hover{
    text-shadow: 1px 1px 0 #000, -1px -1px 0 #000;
}

.button_button__84Icw:active{
    color: #fff;
}

.button_relocate__FstGw{
    left: 5%;
    top: 60%;
}

@media (min-width: 1854px) {
    .button_button__84Icw{
        left: 3%;
    }

    .button_relocate__FstGw{
        left: 2%;
        top: 50%;
    }
}


@media (max-width: 1024px) {
    .button_button__84Icw{
        left: 15%;
        top: 14%;
    }

    .button_relocate__FstGw{
        left: 8%;
        top: 52%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/buttons/prev-form/button.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,UAAU;IACV,QAAQ;IACR,OAAO;IACP,6BAA6B;IAC7B,6BAA6B;;IAE7B,8BAA8B;AAClC;;AAEA;IACI,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;;IAEjB,oCAAoC;AACxC;;AAEA;IACI,QAAQ;AACZ;;AAEA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,6CAA6C;AACjD;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,QAAQ;IACR,QAAQ;AACZ;;AAEA;IACI;QACI,QAAQ;IACZ;;IAEA;QACI,QAAQ;QACR,QAAQ;IACZ;AACJ;;;AAGA;IACI;QACI,SAAS;QACT,QAAQ;IACZ;;IAEA;QACI,QAAQ;QACR,QAAQ;IACZ;AACJ","sourcesContent":[".button{\n    position: fixed;\n    top: 120px;\n    left: 5%;\n    top: 8%;\n    background-color: transparent;\n    background-color: transparent;\n\n    transition: color .3s ease-out;\n}\n\n.text{\n    font-family: 'Jura';\n    font-size: 32px;\n    font-weight: 400;\n    line-height: 23px;\n    \n    transition: text-shadow .3s ease-out;\n}\n\n.fliped{\n    top: 70%;\n}\n\n.button:hover{\n    color: #DDC800;\n    cursor: pointer;\n}\n\n.text:hover{\n    text-shadow: 1px 1px 0 #000, -1px -1px 0 #000;\n}\n\n.button:active{\n    color: #fff;\n}\n\n.relocate{\n    left: 5%;\n    top: 60%;\n}\n\n@media (min-width: 1854px) {\n    .button{\n        left: 3%;\n    }\n\n    .relocate{\n        left: 2%;\n        top: 50%;\n    }\n}\n\n\n@media (max-width: 1024px) {\n    .button{\n        left: 15%;\n        top: 14%;\n    }\n\n    .relocate{\n        left: 8%;\n        top: 52%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `button_button__84Icw`,
	"text": `button_text__GHRm7`,
	"fliped": `button_fliped__1YY4M`,
	"relocate": `button_relocate__FstGw`
};
export default ___CSS_LOADER_EXPORT___;
