import cl from './question.module.css';
import { Variant, iQuestion } from '../../../../constants/game';
import ChooseVariantBtn from '../../../buttons/choose-variant/choose-variant';
import { useState } from 'react';

interface iAnswerQuestion {
    index: number;
    question: iQuestion;
    id: string;
    updateData: (variant: Variant, questionNumber: number) => void;
}

const AnswerQuestion: React.FC<iAnswerQuestion> = ({ index, question, updateData, id }) => {

    const [choosenVariant, setChoosenVariant] = useState<Variant | null>(null);

    const handleChooseVariant = (variant: Variant, number: number) => {
        setChoosenVariant(variant);
        updateData(variant, number);
    };

    return (
        <li className={cl.container} key={index}>
            <header className={cl.question__header}>
                <p>Вопрос {index + 1}</p>
                <p className={cl.question__value}>{question.content}</p>
            </header>
            <main className={cl.question__main}>
                <p className={cl.question__choose}>Выберите один вариант ответа:</p>
                <ul className={cl.question__variants}>
                    {question.answers.map((variant) => (
                        <li key={variant.text}>
                            <ChooseVariantBtn
                                text={variant.text}
                                id={variant._id}
                                name={`choose-variant-${index}`}
                                value={variant.text}
                                checked={choosenVariant?.text === variant.text}
                                onChange={() => handleChooseVariant(variant, index + 1)}
                            />
                        </li>
                    ))}
                </ul>
            </main>
        </li>
    );
}

export default AnswerQuestion;
