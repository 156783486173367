// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/Jura/Jura-VariableFont_wght.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../fonts/Kyiv/KyivTypeSans-VarGX.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*,
*::before,
*::after {
	box-sizing: border-box;
}

*{
    border: none;
    margin: 0px;
    padding: 0px;
}

a, a:link, a:visited  {
    text-decoration: none;
}

a:hover  {
    text-decoration: none;
}

ul, ul li {
	list-style: none;
}

img {
	vertical-align: top;
}

img, svg {
	max-width: 100%;
	height: auto;
}

body {
    overflow-y: hidden;
}

h1, h2, h2, h4{
    font-size: 1em;
}



@font-face {
    font-family: "Jura";
    src: local("Jura"),
     url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: 'Kyiv Type Sans';
    src: 
         url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
    font-weight: 700;
    font-style: normal;
}
  
@font-face {
    font-family: 'Kyiv Type Sans';
    src:
         url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
    font-weight: 400;
    font-style: normal;
}
  

.font-kyev{
    font-family: "Kyev";
}

.font-jura{
    font-family: "Jura";
}

.App{
    min-width: 100%;
    height: 100vh;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/main.css"],"names":[],"mappings":"AAAA;;;CAGC,sBAAsB;AACvB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;CACC,gBAAgB;AACjB;;AAEA;CACC,mBAAmB;AACpB;;AAEA;CACC,eAAe;CACf,YAAY;AACb;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,cAAc;AAClB;;;;AAIA;IACI,mBAAmB;IACnB;+DACmE;IACnE,mBAAmB;AACvB;;AAEA;IACI,6BAA6B;IAC7B;mEACmE;IACnE,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,6BAA6B;IAC7B;mEACmE;IACnE,gBAAgB;IAChB,kBAAkB;AACtB;;;AAGA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,aAAa;AACjB","sourcesContent":["*,\n*::before,\n*::after {\n\tbox-sizing: border-box;\n}\n\n*{\n    border: none;\n    margin: 0px;\n    padding: 0px;\n}\n\na, a:link, a:visited  {\n    text-decoration: none;\n}\n\na:hover  {\n    text-decoration: none;\n}\n\nul, ul li {\n\tlist-style: none;\n}\n\nimg {\n\tvertical-align: top;\n}\n\nimg, svg {\n\tmax-width: 100%;\n\theight: auto;\n}\n\nbody {\n    overflow-y: hidden;\n}\n\nh1, h2, h2, h4{\n    font-size: 1em;\n}\n\n\n\n@font-face {\n    font-family: \"Jura\";\n    src: local(\"Jura\"),\n     url(\"../fonts/Jura/Jura-VariableFont_wght.ttf\") format(\"truetype\");\n    font-weight: normal;\n}\n\n@font-face {\n    font-family: 'Kyiv Type Sans';\n    src: \n         url('../fonts/Kyiv/KyivTypeSans-VarGX.ttf') format('truetype');\n    font-weight: 700;\n    font-style: normal;\n}\n  \n@font-face {\n    font-family: 'Kyiv Type Sans';\n    src:\n         url('../fonts/Kyiv/KyivTypeSans-VarGX.ttf') format('truetype');\n    font-weight: 400;\n    font-style: normal;\n}\n  \n\n.font-kyev{\n    font-family: \"Kyev\";\n}\n\n.font-jura{\n    font-family: \"Jura\";\n}\n\n.App{\n    min-width: 100%;\n    height: 100vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
