// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tour-results_container__u4sSs{
    display: flex;
    flex-direction: column;
}
.tour-results_header__PM\\+j0 {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 38px;
  }

.tour-results_title_container__v2gBV {
    text-align: center;
    flex-grow: 1;
    padding-left: 373px;
    margin-top: 75px;
  }

.tour-results_hint__BVzfJ{
    margin-right: 231px;
}


.tour-results_title__mdCCE{
    font-family: 'Kyiv Type Sans';
    font-size: 64px;
}

.tour-results_text__\\+onsg{
    font-family: 'Kyiv Type Sans';
    font-size: 48px;
}`, "",{"version":3,"sources":["webpack://./src/components/game-process/tour-results/tour-results.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;AACA;IACI,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,mBAAmB;EACrB;;AAEF;IACI,kBAAkB;IAClB,YAAY;IACZ,mBAAmB;IACnB,gBAAgB;EAClB;;AAEF;IACI,mBAAmB;AACvB;;;AAGA;IACI,6BAA6B;IAC7B,eAAe;AACnB;;AAEA;IACI,6BAA6B;IAC7B,eAAe;AACnB","sourcesContent":[".container{\n    display: flex;\n    flex-direction: column;\n}\n.header {\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n    margin-bottom: 38px;\n  }\n\n.title_container {\n    text-align: center;\n    flex-grow: 1;\n    padding-left: 373px;\n    margin-top: 75px;\n  }\n\n.hint{\n    margin-right: 231px;\n}\n\n\n.title{\n    font-family: 'Kyiv Type Sans';\n    font-size: 64px;\n}\n\n.text{\n    font-family: 'Kyiv Type Sans';\n    font-size: 48px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `tour-results_container__u4sSs`,
	"header": `tour-results_header__PM+j0`,
	"title_container": `tour-results_title_container__v2gBV`,
	"hint": `tour-results_hint__BVzfJ`,
	"title": `tour-results_title__mdCCE`,
	"text": `tour-results_text__+onsg`
};
export default ___CSS_LOADER_EXPORT___;
