import { ButtonHTMLAttributes, useContext } from 'react';
import cl from './button.module.css'
import { FormsContext } from '../../../context/context';

interface INextFormButton extends ButtonHTMLAttributes<HTMLButtonElement>{
    isThemeForm: boolean,
}

const NextFormButton: React.FC<INextFormButton> = (props) => {
    const {fliped} = useContext(FormsContext)

    return (
      <button className={`${fliped ? cl.fliped : ''} ${cl.button} ${props.isThemeForm ? cl.relocate : ''}`} onClick={props.onClick} type="submit">
        <p className={cl.text}>д<br/>а<br/>л<br/>е<br/>е</p>
      </button>
    );
  };
  
 
export default NextFormButton;