// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login_container__QDMfk{
    display: flex;
    flex-direction: column;
    gap: 35px;
    width: 350px;
}

.login_title__qigmJ{
    font-size: 36px;
    font-family: 'Kyiv Type Sans';
    font-weight: 350;

    text-align: center;
}

.login_list__2m6kw{
    color: #1A1A1A;
    font-family: 'Jura';
    font-size: 20px;
    display: flex;
    flex-direction: column;
    gap: 23px;
}


.login_button__sSqz7{
    border: none;
    background-color: transparent;
    font-size: 24px;
    font-family: 'Jura';
    color: #3f3e3c;
    font-weight: 700;

    border-radius: 20px;
    padding: 15px;

    transition: background-color .5s ease-in;
}

.login_button__sSqz7:hover{
    background-color: #F7DF00;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/auth/login/login.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,6BAA6B;IAC7B,gBAAgB;;IAEhB,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,mBAAmB;IACnB,eAAe;IACf,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;;AAGA;IACI,YAAY;IACZ,6BAA6B;IAC7B,eAAe;IACf,mBAAmB;IACnB,cAAc;IACd,gBAAgB;;IAEhB,mBAAmB;IACnB,aAAa;;IAEb,wCAAwC;AAC5C;;AAEA;IACI,yBAAyB;IACzB,eAAe;AACnB","sourcesContent":[".container{\n    display: flex;\n    flex-direction: column;\n    gap: 35px;\n    width: 350px;\n}\n\n.title{\n    font-size: 36px;\n    font-family: 'Kyiv Type Sans';\n    font-weight: 350;\n\n    text-align: center;\n}\n\n.list{\n    color: #1A1A1A;\n    font-family: 'Jura';\n    font-size: 20px;\n    display: flex;\n    flex-direction: column;\n    gap: 23px;\n}\n\n\n.button{\n    border: none;\n    background-color: transparent;\n    font-size: 24px;\n    font-family: 'Jura';\n    color: #3f3e3c;\n    font-weight: 700;\n\n    border-radius: 20px;\n    padding: 15px;\n\n    transition: background-color .5s ease-in;\n}\n\n.button:hover{\n    background-color: #F7DF00;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `login_container__QDMfk`,
	"title": `login_title__qigmJ`,
	"list": `login_list__2m6kw`,
	"button": `login_button__sSqz7`
};
export default ___CSS_LOADER_EXPORT___;
