import React, { useState, useEffect } from 'react';
import cl from './search.module.css';
import ThemeCheckbox from './theme-checkbox/theme-checkbox';
const searchIco = require('../../assets/img/search.png');

interface iCustomSearch {
  values: string[];
  onChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  choosenThemes: string[];
}

const CustomSearch = ({ values, onChange, choosenThemes }: iCustomSearch) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const filteredValues = values.filter((value) =>
    value.toLowerCase().includes(searchValue.toLowerCase())
  );

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    setIsOpen(true);
  };

  const handleDocumentClick = (e: MouseEvent) => {
    const target = e.target as HTMLElement;
    const input = document.querySelector(`.${cl.input}`) as HTMLElement;

    if (target !== input && !input.contains(target) && target.tagName !== 'LABEL') {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  return (
    <div className={cl.container}>
      <div className={cl.searchContainer}>
        <input
          className={cl.input}
          type="text"
          placeholder="Поиск тем"
          value={searchValue}
          onChange={handleSearchChange}
          onFocus={handleToggle}
        />
        {isOpen && (
          <ul className={cl.dropdown}>
            {filteredValues.map((value) => (
              <li key={value}>
                <ThemeCheckbox
                  text={value}
                  checked={choosenThemes.includes(value)}
                  onChange={onChange}
                  keyValue={value}
                  id={'themeSearch-' + value}
                />
              </li>
            ))}
          </ul>
        )}
      </div>
      <img className={cl.img} src={searchIco} alt="search icon" />
    </div>
  );
};

export default CustomSearch;
