import { ButtonHTMLAttributes, useContext } from 'react';
import cl from './button.module.css'
import { FormsContext } from '../../../context/context';
interface IPrevFormButton extends ButtonHTMLAttributes<HTMLButtonElement>{
    isThemeForm : boolean,
}


const PrevFormButton: React.FC<IPrevFormButton> = (props) => {
    const {fliped, innerForm} = useContext(FormsContext);
    return (
      <button className={`${fliped ? cl.fliped : ''} ${cl.button} ${props.isThemeForm && innerForm === "findGame" ? cl.relocate : ''}`} onClick={props.onClick} type="button">
        <p className={cl.text} >н<br/>а<br/>з<br/>а<br/>д</p>
      </button>
    );
  };
  
 
export default PrevFormButton;