import { useContext, useEffect, useState } from "react";
import { iAnswer, iTour, Variant } from "../../../constants/game";
import { useNavigate } from "react-router-dom";
import Timer from "../timer/timer";
import axios from "axios";
import cl from './answer.module.css';
import AnswerQuestion from "./question/question";
import { baseGameUrl } from "../../../constants/const";
import { FormsContext } from "../../../context/context";

const Answer = (tour: iTour) => {

    const time = 100 * 1000;

    const [currentTour, setCurrentTour] = useState(tour);
    const [finishTime, setFinishTime] = useState(new Date().getTime() + time);
    const [[diffM, diffS], setDiff] = useState([0, 0]);
    const [tick, setTick] = useState(false);

    const [answers, setAnswers] = useState<iAnswer[]>([]);

    const { currentTeamId, setCurrentGameState } = useContext(FormsContext);

    const nav = useNavigate();

    const fillAnswers = (answers: iAnswer[]) => {
        for (let index = 1; index <= 7; index++) {
            if(!answers.find(({question: questionNumber}) => 
                questionNumber === index
            )){
                answers.push({tour : currentTour.sequence, question : index, isCorrect : false});
            }
        }
    }

    useEffect(() => {
        setCurrentGameState('answer');
        const diff = (finishTime - new Date().getTime()) / 1000;

        const sendAnswers = async () => {
            fillAnswers(answers);
            try {
                const response = await axios.post(`${baseGameUrl}set-results`, { teamId: currentTeamId, answers: answers });
            } catch (e) {
                console.log(e);
            }
        }

        if (diff < 0 && currentTour) {
            sendAnswers();
            setCurrentGameState('results');
            nav(`/resultsId${currentTour.tourId}`);
            return;
        }

        setDiff([
            Math.floor((diff / 60) % 60),
            Math.floor(diff % 60),
        ]);

    }, [tick, finishTime, answers, currentTour, currentTeamId, nav]);

    useEffect(() => {
        const timerID = setInterval(() => setTick(!tick), 1000);
        return () => clearInterval(timerID);
    }, [tick]);

    const updateData = (variant: Variant, questionNumber: number) => {
        setAnswers(prevAnswers => {
            const newAnswers = prevAnswers.filter(answer => answer.question !== questionNumber);
            newAnswers.push({
                tour: currentTour.sequence,
                question: questionNumber,
                isCorrect: variant.isCorrect
            });
            return newAnswers;
        });
    };

    return (
        <div className={cl.mainContainer}>
            <header className={cl.header}>
                <h1 className={cl.title}>Тур {tour.sequence}</h1>
                <Timer diffM={diffM} diffS={diffS} />
            </header>

            <ul className={cl.questions}>
                {tour.questions.map((question, index) => (
                    <AnswerQuestion id={question.id} key={index} index={index} question={question} updateData={updateData} />
                ))}
            </ul>
        </div>
    );
}

export default Answer;