import { useEffect, useState } from 'react';
import cl from './timer.module.css'

const blackTimer = require('../../../assets/img/timer-black.png');
const redTimer = require('../../../assets/img/timer-red.png')
interface TimerProps {
    diffM: number;
    diffS: number;
  }
  
  const Timer: React.FC<TimerProps> = ({diffM, diffS}) => {

    const [isComing, setIsComing] = useState(false);

    useEffect(()=>{
      setIsComing(diffM * 60 + diffS <= 10)
    }, [diffS])

    return (
      <div className={cl.container}>
          <span className={`${cl.text} ${isComing ? cl.coming : ''}`}>{`${diffM.toString().padStart(2, '0')}:${diffS.toString().padStart(2, '0')}`}</span>
          <img className={cl.img} src={isComing ? redTimer : blackTimer} alt="" />
      </div>
    );
  }
  
  export default Timer;
  