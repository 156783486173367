// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../assets/img/rules.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rules_popupOverlay__SbvGS {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.rules_popupContent__mWt8t {
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat 50% 50%;
    padding: 6%;
    border-radius: 10px;
    position: relative;

    overflow: auto;

    width: 100%;
    height: 100%;

    max-width: 1390px;
    max-height: 901px;



}


.rules_title__817Hd{
    font-family: 'Kyiv Type Sans';
    font-size: 32px;
    margin-bottom: 30px;
    text-align: center;
}


.rules_closeButton__aOc5l {
    position: absolute;
    top: 5%;
    right: 4%;

    border: none;
    font-size: 20px;
    cursor: pointer;

}

.rules_list__uJETm{
    display: flex;
    flex-direction: column;
    gap: 21px;
}
.rules_list__uJETm li{
    font-family: 'Jura';
    font-size: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/game-menu/hint/rules/rules.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,8BAA8B;IAC9B,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,qEAAqE;IACrE,WAAW;IACX,mBAAmB;IACnB,kBAAkB;;IAElB,cAAc;;IAEd,WAAW;IACX,YAAY;;IAEZ,iBAAiB;IACjB,iBAAiB;;;;AAIrB;;;AAGA;IACI,6BAA6B;IAC7B,eAAe;IACf,mBAAmB;IACnB,kBAAkB;AACtB;;;AAGA;IACI,kBAAkB;IAClB,OAAO;IACP,SAAS;;IAET,YAAY;IACZ,eAAe;IACf,eAAe;;AAEnB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;AACA;IACI,mBAAmB;IACnB,eAAe;AACnB","sourcesContent":[".popupOverlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: rgba(0, 0, 0, 0.7);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 1000;\n}\n\n.popupContent {\n    background: url('../../../../assets/img/rules.png') no-repeat 50% 50%;\n    padding: 6%;\n    border-radius: 10px;\n    position: relative;\n\n    overflow: auto;\n\n    width: 100%;\n    height: 100%;\n\n    max-width: 1390px;\n    max-height: 901px;\n\n\n\n}\n\n\n.title{\n    font-family: 'Kyiv Type Sans';\n    font-size: 32px;\n    margin-bottom: 30px;\n    text-align: center;\n}\n\n\n.closeButton {\n    position: absolute;\n    top: 5%;\n    right: 4%;\n\n    border: none;\n    font-size: 20px;\n    cursor: pointer;\n\n}\n\n.list{\n    display: flex;\n    flex-direction: column;\n    gap: 21px;\n}\n.list li{\n    font-family: 'Jura';\n    font-size: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popupOverlay": `rules_popupOverlay__SbvGS`,
	"popupContent": `rules_popupContent__mWt8t`,
	"title": `rules_title__817Hd`,
	"closeButton": `rules_closeButton__aOc5l`,
	"list": `rules_list__uJETm`
};
export default ___CSS_LOADER_EXPORT___;
