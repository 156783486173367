// useShakeAnimation.ts
import { useSpring } from '@react-spring/web';
import { useEffect, useState } from 'react';

const useShakeAnimation = (shake: boolean) => {
    const [trigger, setTrigger] = useState(false);
    
    useEffect(() => {
        if (shake) {
            setTrigger(true);
            setTimeout(() => setTrigger(false), 500);
        }
    }, [shake]);

    return useSpring({
        from: { transform: 'translateX(0px)' },
        to: trigger ? [
            { transform: 'translateX(-10px)' },
            { transform: 'translateX(10px)' },
            { transform: 'translateX(-8px)' },
            { transform: 'translateX(8px)' },
            { transform: 'translateX(-6px)' },
            { transform: 'translateX(6px)' },
            { transform: 'translateX(-4px)' },
            { transform: 'translateX(4px)' },
            { transform: 'translateX(0px)' },
        ] : { transform: 'translateX(0px)' },
        config: { duration: 100 },
    });
};

export default useShakeAnimation;
