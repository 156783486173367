

export class Variant {
    text: string;
    isCorrect: boolean;
    _id: string;

    constructor(value: string, isCorrect: boolean) {
        this.text = value;
        this.isCorrect = isCorrect;
        this._id = '';
    }
}

export interface iAnswer { 
    tour: number,
    question: number, 
    isCorrect: boolean, 
}

export interface iQuestion {
    theme: string;
    content: string;
    answers: Variant[];
}

class Question {
    theme: string;
    content: string;
    answers: Variant[];
    id: string; 
    difficulty: number;

    constructor() {
        this.theme = '';
        this.content = '';
        this.answers = [];
        this.id = '';
        this.difficulty = 1;
    }
}


export interface iTour {
    sequence: number;
    questions: Question[];
    results: { questionNumber: number, isCorrect: boolean }[];
    tourId: string;
}


export class Tour implements iTour{
    sequence: number;
    questions: Question[];
    results: { questionNumber: number, isCorrect: boolean }[];
    tourId: string;

    constructor() {
        this.sequence = 0;
        this.questions = [];
        this.results = [];
        this.tourId = ''
    }

      
}

export class Team {
    teamName: string;
    id: string;
    results: {[key: string]: boolean}
    score: number;
    totalScore: number;

    constructor(name: string, index: number) {
        this.teamName = name;
        this.id = '';
        this.results = {
            '1': true,
            '2': false,
            '3': false,
            '4': true,
            '5': true,
            '6': false,
            '7': false,
        }
        this.score = Object.values(this.results).reduce((acc, cur) => {
            if (cur) {
              return acc + 1;
            }
            return acc;
          }, 0);

        this.totalScore = 0
    }
}


class GameSession {
    _id: string;
    initiatorTeamName: string;
    currentTeamsCount: number;
    gameMode: string;
    teams: Team[];
    themes: String[];
    tours: Tour[];
    teamsCount: number;

    constructor(id: string) {
        this.initiatorTeamName = ''
        this.gameMode = 'Тематический'
        this.currentTeamsCount = 0;
        this._id = id;
        this.teams = [];
        this.tours = Array.from({ length: 7}, () => new Tour());
        this.teamsCount = 0;
        this.themes = [];
    }
}

export {GameSession};