// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.question_question__header__frc6J{
    background-color: rgba(247, 223, 0, .2);
    padding: 70px 270px;
    font-family: 'Kyiv Type Sans';
    font-size: 48px;

    text-align: center;
}

.question_question__value__mblZm{
    font-family: 'Jura';
    
}

.question_container__ixJrS{
    min-height: 100vh;
}

.question_question__variants__3kjuX{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: row;

    row-gap: 53px;

    font-family: 'Jura';
    font-size: 48px;
    padding: 4% 1%;

}

.question_question__choose__MjgiN{
    font-family: 'Jura';
    font-size: 48px;
}
.question_question__main__FgGRj{
    padding: 44px 121px;
}

@media(max-width: 1024px){
    .question_question__header__frc6J{
        font-size: 40px;
    }

    .question_question__variants__3kjuX{
        font-size: 32px;
    }

    .question_question__choose__MjgiN{
        font-size: 40px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/game-process/tour-answer/question/question.module.css"],"names":[],"mappings":"AAAA;IACI,uCAAuC;IACvC,mBAAmB;IACnB,6BAA6B;IAC7B,eAAe;;IAEf,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;;AAEvB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;;IAEnB,aAAa;;IAEb,mBAAmB;IACnB,eAAe;IACf,cAAc;;AAElB;;AAEA;IACI,mBAAmB;IACnB,eAAe;AACnB;AACA;IACI,mBAAmB;AACvB;;AAEA;IACI;QACI,eAAe;IACnB;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".question__header{\n    background-color: rgba(247, 223, 0, .2);\n    padding: 70px 270px;\n    font-family: 'Kyiv Type Sans';\n    font-size: 48px;\n\n    text-align: center;\n}\n\n.question__value{\n    font-family: 'Jura';\n    \n}\n\n.container{\n    min-height: 100vh;\n}\n\n.question__variants{\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    grid-auto-flow: row;\n\n    row-gap: 53px;\n\n    font-family: 'Jura';\n    font-size: 48px;\n    padding: 4% 1%;\n\n}\n\n.question__choose{\n    font-family: 'Jura';\n    font-size: 48px;\n}\n.question__main{\n    padding: 44px 121px;\n}\n\n@media(max-width: 1024px){\n    .question__header{\n        font-size: 40px;\n    }\n\n    .question__variants{\n        font-size: 32px;\n    }\n\n    .question__choose{\n        font-size: 40px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"question__header": `question_question__header__frc6J`,
	"question__value": `question_question__value__mblZm`,
	"container": `question_container__ixJrS`,
	"question__variants": `question_question__variants__3kjuX`,
	"question__choose": `question_question__choose__MjgiN`,
	"question__main": `question_question__main__FgGRj`
};
export default ___CSS_LOADER_EXPORT___;
