// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error_text__6Dqh5{
    color: #9D2929;

    font-family: 'Jura';
    font-size: 32px;
}`, "",{"version":3,"sources":["webpack://./src/components/validate/error.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;;IAEd,mBAAmB;IACnB,eAAe;AACnB","sourcesContent":[".text{\n    color: #9D2929;\n\n    font-family: 'Jura';\n    font-size: 32px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": `error_text__6Dqh5`
};
export default ___CSS_LOADER_EXPORT___;
