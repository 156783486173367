
const Themes = [
  'Исторические события',
  "Изобретения и открытия",
  "IT",
  "Экономика",
  "Биология ",
  "Литература",
  'Искусство',
]

const baseGameUrl = 'https://quiz-game.stk8s.66bit.ru/api/game/';
const baseAuthUrl = 'https://quiz-game.stk8s.66bit.ru/api/user/';

//const baseGameUrl = 'http://localhost:5000/api/game/';
//const baseAuthUrl = 'http://localhost:5000/api/user/';

const playersCount = [1, 2, 3, 4, 5, 6];

  
export { Themes, playersCount, baseGameUrl, baseAuthUrl};
