import cl from './theme-checkbox.module.css'
import React, { InputHTMLAttributes } from 'react';

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement>{
    text: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    keyValue: string;
}

const ThemeCheckbox: React.FC<CheckboxProps> = (props) => {
  return (
    <div key={props.keyValue}>
        <input type="checkbox" id={props.id} checked={props.checked} onChange={props.onChange} className={cl.radio} />
        <label htmlFor={props.id} className={cl.label}>{props.text}</label>
    </div>

  );
};

export default ThemeCheckbox;
