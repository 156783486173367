import Username from "../../game-menu/user-name/user-name";
import classes from './wait-room.module.css';
import GameHint from "../../game-menu/hint/game-hint";
import { useState, useEffect, useContext } from "react";
import { Team } from "../../../constants/game";
import { useNavigate } from "react-router-dom";
import { FormsContext } from "../../../context/context";
import axios from "axios";
import { baseGameUrl } from "../../../constants/const";
import { animated } from "@react-spring/web";
import useShakeAnimation from "../../../utils/useShakeAnimation";
import CustomError from "../../validate/error";
import ConfirmationModal from "../../game-menu/confirm/confirm";

const WaitRoom = () => {
  const [teamsCount, setTeamsCount] = useState(0);
  const [teams, setTeams] = useState<Team[]>([]);
  const [maxTeamsCount, setMaxTeamsCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [shakeBegin, setShakeBegin] = useState(false);
  const [isCreator, setIsCreator] = useState(false);
  const [error, setError] = useState('');

  const animateBegin = useShakeAnimation(shakeBegin);
  

  const [shakeFetch, setShakeFetch] = useState(false);
  const animateFetch = useShakeAnimation(shakeBegin);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { setCurrentTeamId, user, setTours, setFliped, setInnerForm, privateCode, teamName, setPrivateCode, tours, setCurrentTour, setCurrentGameState, setUser } = useContext(FormsContext);
  const nav = useNavigate();

  useEffect(() => {
    setFliped(true);
  })

  useEffect(() => {
    const fetchGame = async () => {
      try {
        const [responseGame, responseTours, responseTeams] = await Promise.all([
          axios.get(`${baseGameUrl}get-game`, { params: { gameId: user.gameId } }),
          axios.get(`${baseGameUrl}get-tours`, { params: { gameId: user.gameId } }),
          axios.get(`${baseGameUrl}get-teams`, { params: { gameId: user.gameId } })
        ]);
        console.log(responseGame);
        
        setIsCreator(responseGame.data.initiatorTeamName === teamName);
        setPrivateCode(responseGame.data.privateCode);
        setTeamsCount(responseGame.data.currentTeamsCount);
        setTeams(responseTeams.data.teams);
        setMaxTeamsCount(responseGame.data.teamsCount);
        setTours(responseTours.data);
        setCurrentTour(responseTours.data[0].tourId);
        setCurrentGameState('tour');

        console.log(responseTeams.data.teams);


        teams.map((item, index) => {
            if(item.teamName === teamName) {
              setCurrentTeamId(item.id);
            }
        });
        
                   
        
        setLoading(false);

      } catch (e) {
        console.log(e);
        
        setShakeFetch(true);
        setError('Ошибка сервера')
        setInterval(() =>{ setShakeFetch(false); setError('')}, 3000);
        
      } finally {


      }
    };


    const intervalId = setInterval(fetchGame, shakeFetch ? 6000 : 1000);
    return () => clearInterval(intervalId);
  }, [user.gameId, setTours, setInnerForm, setShakeBegin, shakeFetch, setPrivateCode, teamName, setCurrentTour, setCurrentGameState, setFliped, setCurrentTeamId]);

  const renderTeam = (command: Team) => (
    <li className={classes.listItem} key={command.teamName}>
      {`${command.teamName}` } <span>{command.teamName === teamName ? '(это вы!)' : ''}</span>
    </li>
  );

  const renderEmptyTeam = (index: number) => (
    <li className={`${classes.inactiveTeam} ${classes.listItem}`} key={`empty-${index}`}>
      Команда {index + 1}
    </li>
  );

  const handleGameBegin = async () => {
    try {
      if (teamsCount === maxTeamsCount) {
        await axios.post(`${baseGameUrl}set-started`, { gameId: user.gameId });
        setInnerForm('tour');
        setCurrentGameState('tour');
        nav(`/tourId${tours[0].tourId}`);
      }
      else{
        setShakeBegin(true);
        console.log(1);
        
        setError('Не все подключились к игре');
        setInterval(() => {setShakeBegin(false); setError('')}, 3000);
      }
      
    } catch(e) {
      setShakeBegin(true);
      setError('Ошибка сервера')
      setInterval(() =>{ setShakeBegin(false); setError('')}, 3000);
    }
  }

  const handleGameClose = async () =>{
    try {
      const response = await axios.post(`${baseGameUrl}remove-team`, { gameId: user.gameId, userId: user._id });

      if(response.data.game.currentTeamsCount === 0){
        await axios.delete(`${baseGameUrl}delete-game`, {  
          params : {
            gameId: user.gameId,
            userId: user._id,
          }
        });
      }


      const tempUser = {...user, gameId:''};
      localStorage.clear();

      setUser(tempUser);
      console.log(response.data.game);
      
      
      nav('/main');
    } catch (e) {
      setShakeBegin(true);
      setInterval(() => setShakeBegin(false), 2000);
    }
  }

  if (loading) {
    return !shakeFetch ?
     <div>Loading...</div> :
     <animated.div className={classes.error}>
        <CustomError errorText={error}/>
     </animated.div>
     
  }

  return (
    <div className={classes.container}>
      <header className={classes.header}>
        <Username nickname={user.username}  />
        <h1 className={classes.title}>
          Ждём все <br />
          команды
        </h1>

        <GameHint />
      </header>
      <div className="">
                
      </div>

      <animated.div className={classes.listContainer}>
        <div className={classes.controls}>
          <button className={classes.button}  onClick={() => setIsModalOpen(true)}>Выйти</button>
          <p className={classes.private__code}>{privateCode ? `Код комнаты: ${privateCode}` : ''}</p>
          {
            isCreator ? 
            <animated.div style={animateBegin}>
              <button className={classes.button}  onClick={() => handleGameBegin()}>НАЧАТЬ ИГРУ</button>
            </animated.div>:
          ''
        }
        </div>
        {
          shakeBegin ? 
          <CustomError errorText={error}/> :
          ''
        }
        {
          shakeFetch ?
          <CustomError errorText="Ошибка при получении данных игры"/> :
          <>
            <p className={classes.text}>
              Подключились<span className={classes.span}>{teamsCount}/{maxTeamsCount}</span>:
            </p>
            <ul className={classes.list}>
              {teams.map(renderTeam)}
              {Array.from({ length: maxTeamsCount - teamsCount }, (_, index) =>
              renderEmptyTeam(index + teamsCount)
             )}
            </ul>
          </>
        }       
        
      </animated.div>


      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleGameClose}
        message="Точно выйти из комнаты?"
      />


      <div className={classes.decoration}></div>
    </div>
  );
};

export default WaitRoom;
