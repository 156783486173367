import React, { useState } from 'react';
import { Link, useLocation, Routes, Route } from 'react-router-dom';
import { useSpring, animated } from '@react-spring/web';
import cl from './auth.module.css';
import RegisterForm from '../reg/reg';
import LoginForm from '../login/login';

const Auth = () => {
  const location = useLocation();
  const [success, setSuccess] = useState(false);

  const buttonAnimation = useSpring({
    transform: success ? 'scale(1.5)' : 'scale(1)',
    config: { tension: 200, friction: 12 },
  });

  const rightButton = () => (
    <animated.svg
      style={buttonAnimation}
      width="37"
      height="55"
      viewBox="0 0 28 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <svg
        width="37"
        height="55"
        viewBox="0 0 37 55"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_1_432)">
          <path
            d="M29.9646 25.3014L10.4834 44.0472C8.89518 45.5755 6.25 44.4499 6.25 42.2457L6.25 4.75429C6.25 2.55014 8.89518 1.42454 10.4834 2.95284L29.9646 21.6986C30.9866 22.6821 30.9866 24.3179 29.9646 25.3014Z"
            stroke="#F7DF00"
            strokeWidth="3"
            shapeRendering="crispEdges"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_1_432"
            x="0.75"
            y="0.746307"
            width="35.4812"
            height="53.5074"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_1_432"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1_432"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </animated.svg>
  );
  
  const leftButton = () => (
    <svg
      width="37"
      height="56"
      viewBox="0 0 37 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_1_395)">
        <path
          d="M6.86454 22.2381L26.4764 2.49562C28.0489 0.912651 30.75 2.02625 30.75 4.25751L30.75 43.7425C30.75 45.9738 28.0489 47.0874 26.4764 45.5044L6.86454 25.7619C5.89604 24.7869 5.89604 23.2131 6.86454 22.2381Z"
          stroke="#1A1A1A"
          strokeWidth="3"
          shapeRendering="crispEdges"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1_395"
          x="0.638184"
          y="0.249481"
          width="35.6118"
          height="55.501"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1_395"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1_395"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
  



  return (
    <div className={cl.container}>
      {location.pathname !== '/auth/reg' ? 
        <Link className={cl.linkLeft} to="reg">{leftButton()}</Link> :
        ''
      }
      <Routes>
        <Route path="reg" index element={<RegisterForm setSuccess={setSuccess} success={success} />} />
        <Route path="log" element={<LoginForm />} />
      </Routes>
      {location.pathname !== '/auth/log' ? 
        <Link className={cl.linkRight} to="log">{rightButton()}</Link> :
        ''
      }
    </div>
  )
}

export default Auth;
