import { InputHTMLAttributes } from 'react';
import cl from './button.module.css'

interface RadioButtonProps extends InputHTMLAttributes<HTMLInputElement> {
    text: string;
  }
  
const RadioButton: React.FC<RadioButtonProps> = (props) => {
return (
    <div>
        <input
            type={props.type}
            id={props.id}
            name={props.name}
            value={props.value}
            checked={props.checked}
            onChange={props.onChange}
            className={cl.radio}
        />        
        <label className={cl.label} htmlFor={props.id}>
            <RadioIcon />
            <p>{props.text}</p>
           
        </label>
    </div>
);
};

const RadioIcon = () => (
<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12.5" cy="12.5" r="12.5" fill="#D2CEC4" fillOpacity="0.2"/>
    <line x1="6.7328" y1="12.3196" x2="13.2328" y2="19.3197" stroke="white" strokeOpacity="0.2" strokeWidth="2"/>
    <line y1="-1" x2="15.0083" y2="-1" transform="matrix(0.499723 -0.866185 0.828048 0.560657 12.5 20)" stroke="white" strokeOpacity="0.2" strokeWidth="2"/>
</svg>


);

export default RadioButton