import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';

function getStorageValue<T>(key: string, defaultValue: T): T {

    const saved = localStorage.getItem(key);
    const initial = saved ? JSON.parse(saved) : defaultValue;
    return initial as T;
    
}

export function UseLocalStorage <T>(key: string, defaultValue: T): [T, Dispatch<SetStateAction<T>>] {

    const [value, setValue] = useState<T>(() => {
      return getStorageValue<T>(key, defaultValue);
    });

    useEffect(() => {
      localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);

    return [value, setValue];
};
