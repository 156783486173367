import React, { useContext, useState, useEffect } from "react";
import { animated } from '@react-spring/web';
import cl from './login.module.css';
import { useNavigate } from "react-router-dom";
import { UseLocalStorage } from "../../../context/useLocalStorage";
import { FormsContext } from "../../../context/context";
import axios from "axios";
import { baseAuthUrl } from "../../../constants/const";
import CustomInput from "../../buttons/input/custom-input";
import useShakeAnimation from '../../../utils/useShakeAnimation'; 
import CustomError from "../../validate/error";

const LoginForm: React.FC = () => {
    const [login, setLogin] = UseLocalStorage('email', '');
    const [password, setPassword] = useState('');
    const [shake, setShake] = useState(false);
    const [errorText, setErrorText] = useState('');

    const nav = useNavigate();
    const { setUser, setJwtToken } = useContext(FormsContext);

    const shakeAnimation = useShakeAnimation(shake); 

    const onRegSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        try {
            if(!login){
                setErrorText('Введите логин');
                throw new Error();
            }
            else if(!password){
                setErrorText('Введите пароль');
                throw new Error();
            }
            const response = await axios.post(`${baseAuthUrl}login`, { email: login, password });
            setJwtToken(response.data.token);
            setUser(response.data.user);
            nav('/main');
        } catch (err) {
            setShake(true); 
            setTimeout(() => {setShake(false); setErrorText('')}, 1000);  
            if (axios.isAxiosError(err)) {
                setErrorText(err.response?.data.message)
            }
        }
    };

    const handleLoginChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setLogin(value);
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setPassword(value);
    };

    return (
        <form className={cl.container}>

            {
                errorText ? 
                <CustomError errorText={errorText} fs={20}></CustomError> : 
                <h1 className={cl.title}>Вход</h1>
            }
            <ul className={cl.list}>
                <li>
                    <animated.div style={shakeAnimation}>
                        <CustomInput
                            placeholder='Ivan1990@mail.ru'
                            value={login}
                            name="login"
                            type="text"
                            onChange={handleLoginChange}
                            label="почта"
                        />
                    </animated.div>
                </li>
                <li>
                    <animated.div style={shakeAnimation}>
                        <CustomInput
                            placeholder='124ds2'
                            type="password"
                            value={password}
                            name="password"
                            onChange={handlePasswordChange}
                            label="пароль"
                        />
                    </animated.div>
                </li>
            </ul>
            <button className={cl.button} onClick={onRegSubmit}>войти</button>
        </form>
    );
};

export default LoginForm;
