import './assets/styles/main.css';
import { useState, useEffect } from 'react';
import { FormsContext, defaultState } from './context/context';
import MainWindow from './components/game-menu/main-form/main-form';
import GameBeginForm from './components/game-join/begin-form/game-begin-form';
import { BrowserRouter, Navigate, NavigateFunction, Route, Routes, useNavigate } from 'react-router-dom';
import WaitRoom from './components/game-join/wait-room/wait-room';
import Auth from './components/auth/auth/auth';
import SelectThemes from './components/game-join/select-themes/select-themes';
import Question from './components/game-process/tour-show/show-question';
import Answer from './components/game-process/tour-answer/answer';
import { GameSession, Tour } from './constants/game';
import TourResults from './components/game-process/tour-results/tour-results';
import { UseLocalStorage } from './context/useLocalStorage';
import FindGame from './components/game-join/join-game/find-game';
import User from './constants/user';
import { baseGameUrl } from './constants/const';
import axios from 'axios';

export const game = new GameSession('123125135');

function App() {

  const [innerForm, setInnerForm] = UseLocalStorage('innerForm', '');
  const [fliped, setFliped] = UseLocalStorage('fliped', false);
  const [isReady, setIsReady] = UseLocalStorage('isNextReady', false);
  const [user, setUser] = UseLocalStorage('user', new User('', '', '', '', ''));
  const [jwtToken, setJwtToken] = UseLocalStorage('curJwt', '');
  const [privateCode, setPrivateCode] = UseLocalStorage('privateCode', '');
  const [currentTeamId, setCurrentTeamId] = UseLocalStorage('currentTeamId', '');
  const [teamName, setTeamName] = UseLocalStorage('teamName', '');
  const [currentTour, setCurrentTour] = UseLocalStorage('currentTour', '');
  const [currentGameState, setCurrentGameState] = UseLocalStorage('gameState', '');
  const [currentQuestion, setCurrentQuestion] = UseLocalStorage('question', 0);

  const [gameIsCreated, setGameIsCreated] = useState(false);
  const [gameIsStarted, setGameIsStarted] = useState(false);
  const [tours, setTours] = UseLocalStorage('tours', [new Tour()]);
  const [started, setStarted] = UseLocalStorage('started', false);
  const [loading, setLoading] = useState(true);

  const fetchGame = async (navigate: NavigateFunction) => {
    if (user.gameId) {
      try {
        const responseGame = await axios.get(`${baseGameUrl}get-game`, {
          params: { gameId: user.gameId }
        });


        setGameIsCreated(responseGame.data.isCreated);
        setGameIsStarted(responseGame.data.isStarted);


        if (responseGame.data.isStarted && !started) {
          setStarted(true);
          const responseTours = await axios.get(`${baseGameUrl}get-tours`, { params: { gameId: user.gameId } });

          setTours(responseTours.data);
          setCurrentTour(responseGame.data.tours[0]);
          setInnerForm('tour');
          setCurrentGameState('tour');
          navigate(`/tourId${responseGame.data.tours[0]}`);

        } else if (responseGame.data.isCreated && !responseGame.data.isStarted) {
          setInnerForm('waitRoom');
          navigate(`/waitRoomId${user.gameId}`);
        } else if(started){

          switch(currentGameState){
            case 'tour':
              navigate(`/tourId${currentTour}`);
              break;
            case 'answer':
              navigate(`/answerId${currentTour}`);
              break;
            case 'results':
              navigate(`/resultsId${currentTour}`);
              break;
          }
          
        }

      } catch (err) {
        console.log(1);
        
        const tempUser = {...user, gameId: ''};
        localStorage.clear();
        setUser(tempUser);
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
      if(!user._id && innerForm !== 'reg'){
        navigate('/auth/reg')
        setInnerForm('reg')
      }
    }
  };

  const GameFetcher = () => {
    const navigate = useNavigate();

    useEffect(() => {
      fetchGame(navigate);
    }, [navigate]);

    return loading ? <div>Loading...</div> : null;
  };

  const NotFound = () => {
    return <Navigate to={user._id === '' ? "/auth/reg" : "/main"} replace />;
  };

  return (
    <FormsContext.Provider value={{
      currentTeamId, setCurrentTeamId, tours, setTours, jwtToken, setJwtToken,
      user, setUser, innerForm, setInnerForm,
      fliped, setFliped, isReady, setIsReady, forms: defaultState.forms,
      setPrivateCode, privateCode, teamName, setTeamName, currentTour, setCurrentTour,
      setCurrentGameState, currentGameState, currentQuestion, setCurrentQuestion
    }}>
      <div className="App">
        <BrowserRouter>
          <GameFetcher />
          <Routes>
            <Route path='/auth/*' element={<Auth />} />
            <Route path='/main/*' element={<MainWindow />}>
              <Route index element={<GameBeginForm />} />
              {Object.entries(defaultState.forms).map(([key, form]) => (
                <Route
                  key={key}
                  path={`${key}Id${user.gameId}`}
                  element={form.current}
                />
              ))}
            </Route>
            <Route path='/main/findGame' element={<FindGame />} />
            <Route path={`/waitRoomId${user.gameId}`} element={<WaitRoom />} />
            <Route path={`/selectThemesId${user.gameId}`} element={<SelectThemes />} />
            {gameIsStarted && currentGameState ==='tour' ?
                (() => {
                    const tour = tours.find((tour) => tour.tourId === currentTour);
                    if (tour && tour.sequence !== undefined && tour.questions && tour.tourId) {
                        return (
                            <Route
                                key={tour.sequence}
                                path={`/tourId${currentTour}`}
                                element={<Question {...tour} />}
                            />
                        );
                    }
                    return null;
                })() : ''
            }

            {gameIsStarted && currentGameState==='answer'?
                (() => {
                  const tour = tours.find((tour) => tour.tourId === currentTour);
                  if (tour && tour.sequence !== undefined && tour.questions && tour.tourId) {
                  return (<Route
                    key={tour.sequence}
                    path={`/answerId${tour.tourId}`}
                    element={<Answer {...tour} />}
                  />)
                }
                return null;
                })(): ''}

                {gameIsStarted && currentGameState==='results' ?
                (() => {
                  const tour = tours.find((tour) => tour.tourId === currentTour);
                  if (tour && tour.sequence !== undefined && tour.questions && tour.tourId) {
                  return (<Route
                    key={tour.sequence}
                    path={`/resultsId${tour.tourId}`}
                    element={<TourResults {...tour} />}
                  />)
                }
                return null;
                })(): ''}
            {innerForm !== 'tour' ? 
              <Route path="*" element={<NotFound />} />
            : null }
          </Routes>
        </BrowserRouter>
      </div>
    </FormsContext.Provider>
  );
}

export default App;
