// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button_radio__YvqLB{
    margin-right: 26px;
    display: none;

}
.button_label__nQLgo{
    font-size: 48px;
    font-family: 'Jura';
    display: flex;
    align-items: center;
    color: #fff;

    justify-content: center;
    height: 100%;

    gap: 26px;
}

.button_radio__YvqLB[type = "checkbox"] + label{
    color: #fff;
    font-size: 24px;
 
    align-items: center;
    text-align: center;
    vertical-align: middle;

}

.button_radio__YvqLB:checked + label svg circle {
    fill: #F7DF00;
    fill-opacity: 1;
}

.button_radio__YvqLB:checked + label svg line {
    stroke-opacity: 1;
    stroke: black;
}

.button_radio__YvqLB:hover + label svg line{
    stroke-opacity: 1;
    cursor: pointer;
}

.button_radio__YvqLB:hover + label svg circle{
    fill-opacity: 1;
    cursor: pointer;
}





svg circle,
svg line {
  transition: fill-opacity .3s ease-out,
              stroke-opacity .3s ease-out,
              fill .3s ease-out,
              stroke .3s ease-out;
}
`, "",{"version":3,"sources":["webpack://./src/components/buttons/radio-checkbox/button.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;;AAEjB;AACA;IACI,eAAe;IACf,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,WAAW;;IAEX,uBAAuB;IACvB,YAAY;;IAEZ,SAAS;AACb;;AAEA;IACI,WAAW;IACX,eAAe;;IAEf,mBAAmB;IACnB,kBAAkB;IAClB,sBAAsB;;AAE1B;;AAEA;IACI,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,aAAa;AACjB;;AAEA;IACI,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,eAAe;AACnB;;;;;;AAMA;;EAEE;;;iCAG+B;AACjC","sourcesContent":[".radio{\n    margin-right: 26px;\n    display: none;\n\n}\n.label{\n    font-size: 48px;\n    font-family: 'Jura';\n    display: flex;\n    align-items: center;\n    color: #fff;\n\n    justify-content: center;\n    height: 100%;\n\n    gap: 26px;\n}\n\n.radio[type = \"checkbox\"] + label{\n    color: #fff;\n    font-size: 24px;\n \n    align-items: center;\n    text-align: center;\n    vertical-align: middle;\n\n}\n\n.radio:checked + label svg circle {\n    fill: #F7DF00;\n    fill-opacity: 1;\n}\n\n.radio:checked + label svg line {\n    stroke-opacity: 1;\n    stroke: black;\n}\n\n.radio:hover + label svg line{\n    stroke-opacity: 1;\n    cursor: pointer;\n}\n\n.radio:hover + label svg circle{\n    fill-opacity: 1;\n    cursor: pointer;\n}\n\n\n\n\n\nsvg circle,\nsvg line {\n  transition: fill-opacity .3s ease-out,\n              stroke-opacity .3s ease-out,\n              fill .3s ease-out,\n              stroke .3s ease-out;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radio": `button_radio__YvqLB`,
	"label": `button_label__nQLgo`
};
export default ___CSS_LOADER_EXPORT___;
