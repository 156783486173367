// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form_container__0zI3Q{
    display: flex;
    flex-direction: column;
    gap: 63px;
    margin-bottom: 15%;
}


.form_popup__container__6yIY\\+ {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;

    align-items: center;
}

.form_popup__content__aIfSF {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.form_hidden__9hF5- {
    display: none;
}


.form_submit__popup__vfXc-{
    font-size: 32px;
    padding: 12px;
    border-radius: 20px;
    border: none;
    background-color: transparent;
}

.form_submit__popup__vfXc-:hover{
    border: 2px solid #f7df00;
    cursor: pointer;
}

.form_submit__popup__vfXc-:active{
    background-color: rgba(247, 223, 0, .4);
}

`, "",{"version":3,"sources":["webpack://./src/components/game-join/begin-form/form.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,kBAAkB;AACtB;;;AAGA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,aAAa;IACb,uBAAuB;;IAEvB,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,kBAAkB;IAClB,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,aAAa;AACjB;;;AAGA;IACI,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,6BAA6B;AACjC;;AAEA;IACI,yBAAyB;IACzB,eAAe;AACnB;;AAEA;IACI,uCAAuC;AAC3C","sourcesContent":[".container{\n    display: flex;\n    flex-direction: column;\n    gap: 63px;\n    margin-bottom: 15%;\n}\n\n\n.popup__container {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.5);\n    display: flex;\n    justify-content: center;\n\n    align-items: center;\n}\n\n.popup__content {\n    background: white;\n    padding: 20px;\n    border-radius: 8px;\n    text-align: center;\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n}\n\n.hidden {\n    display: none;\n}\n\n\n.submit__popup{\n    font-size: 32px;\n    padding: 12px;\n    border-radius: 20px;\n    border: none;\n    background-color: transparent;\n}\n\n.submit__popup:hover{\n    border: 2px solid #f7df00;\n    cursor: pointer;\n}\n\n.submit__popup:active{\n    background-color: rgba(247, 223, 0, .4);\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `form_container__0zI3Q`,
	"popup__container": `form_popup__container__6yIY+`,
	"popup__content": `form_popup__content__aIfSF`,
	"hidden": `form_hidden__9hF5-`,
	"submit__popup": `form_submit__popup__vfXc-`
};
export default ___CSS_LOADER_EXPORT___;
