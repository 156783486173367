// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* confirmation-modal.module.css */
.confirm_modalOverlay__9-4W- {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .confirm_modal__yEN6n {

    font-family: 'Jura';
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    text-align: center;
  }
  
  .confirm_message__yQjNO {
    margin-bottom: 20px;
    font-size: 18px;
  }
  
  .confirm_buttons__a\\+7bz {
    display: flex;
    justify-content: space-around;

  }

  .confirm_button__TLQkC{
    font-family: 'Jura';
    font-size: 20px;
    font-weight: 700;
    padding: 12px;
    border-radius: 12px;
    background-color: #F7DF00;
  }`, "",{"version":3,"sources":["webpack://./src/components/game-menu/confirm/confirm.module.css"],"names":[],"mappings":"AAAA,kCAAkC;AAClC;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,8BAA8B;IAC9B,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;EACf;;EAEA;;IAEE,mBAAmB;IACnB,iBAAiB;IACjB,aAAa;IACb,kBAAkB;IAClB,uCAAuC;IACvC,gBAAgB;IAChB,WAAW;IACX,kBAAkB;EACpB;;EAEA;IACE,mBAAmB;IACnB,eAAe;EACjB;;EAEA;IACE,aAAa;IACb,6BAA6B;;EAE/B;;EAEA;IACE,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,yBAAyB;EAC3B","sourcesContent":["/* confirmation-modal.module.css */\n.modalOverlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background: rgba(0, 0, 0, 0.5);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 1000;\n  }\n  \n  .modal {\n\n    font-family: 'Jura';\n    background: white;\n    padding: 20px;\n    border-radius: 8px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n    max-width: 400px;\n    width: 100%;\n    text-align: center;\n  }\n  \n  .message {\n    margin-bottom: 20px;\n    font-size: 18px;\n  }\n  \n  .buttons {\n    display: flex;\n    justify-content: space-around;\n\n  }\n\n  .button{\n    font-family: 'Jura';\n    font-size: 20px;\n    font-weight: 700;\n    padding: 12px;\n    border-radius: 12px;\n    background-color: #F7DF00;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalOverlay": `confirm_modalOverlay__9-4W-`,
	"modal": `confirm_modal__yEN6n`,
	"message": `confirm_message__yQjNO`,
	"buttons": `confirm_buttons__a+7bz`,
	"button": `confirm_button__TLQkC`
};
export default ___CSS_LOADER_EXPORT___;
