import React, { useContext, useEffect, useState } from "react";
import cl from './reg.module.css'
import axios from "axios";
import { baseAuthUrl } from "../../../constants/const";
import CustomInput from "../../buttons/input/custom-input";
import {animated} from '@react-spring/web';
import useShakeAnimation from "../../../utils/useShakeAnimation";
import CustomError from "../../validate/error";
import { useNavigate } from "react-router-dom";
import { FormsContext } from "../../../context/context";

type RegisterFormProps = {
    setSuccess: React.Dispatch<React.SetStateAction<boolean>>;
    success: boolean
};


const RegisterForm: React.FC<RegisterFormProps> = ({ setSuccess, success }) => {
    const [shake, setShake] = useState(false);
    const [errorText, setErrorText] = useState('');


    const {setUser, setJwtToken} = useContext(FormsContext);
    const nav = useNavigate();
    
    const [formData, setFormData] = useState({
        login: '',
        password: '',
        nickname: '',
        repPas: ''
    });

    const shakeAnimation = useShakeAnimation(shake);

    const onRegSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        try {
            if (!formData.login || !formData.password || !formData.repPas || !formData.nickname) {
                setErrorText('Не все поля заполнены');
                throw new Error();
            }
            if (formData.repPas !== formData.password) {
                setErrorText('Пароль не совпадает');
                throw new Error();
            }

            setErrorText('');
            const response = await axios.post(`${baseAuthUrl}registration`, { username: formData.nickname, password: formData.password, email: formData.login });

            const userResponse = await axios.post(`${baseAuthUrl}login`, { email: formData.login, password: formData.password });
            setJwtToken(userResponse.data.token);
            setUser(userResponse.data.user);
            nav('/main');
            setSuccess(true);
            // window.location.href = '/auth/log';

        } catch (e) {
            setShake(true);
            setTimeout(() => { setShake(false); setErrorText('') }, 2000);
            console.log(e);

            if (axios.isAxiosError(e)) {
                setErrorText(e.response?.data.message);
            }
        }
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    useEffect(() => {
        setSuccess(false);
    }, [setSuccess]);

    return (
        <form className={`${cl.container} `} >
            {errorText ? 
                <CustomError errorText={errorText} fs={24} /> :
                <h1 className={`${cl.title} ${success ? cl.success : ''}`}>Регистрация</h1>
            }
            <ul className={cl.list}>
                <li>
                    <animated.div style={shakeAnimation}>
                        <CustomInput
                            label="почта"
                            placeholder="введите почту"
                            value={formData.login}
                            type="email"
                            name="login"
                            onChange={handleChange}
                            required
                        />
                    </animated.div>
                </li>
                <li>
                    <animated.div style={shakeAnimation}>
                        <CustomInput
                            label="ваше имя"
                            placeholder="введите имя"
                            value={formData.nickname}
                            type="text"
                            name="nickname"
                            onChange={handleChange}
                            required
                        />
                    </animated.div>
                </li>
                <li>
                    <animated.div style={shakeAnimation}>
                        <CustomInput
                            label="пароль"
                            placeholder="введите пароль"
                            value={formData.password}
                            type="password"
                            name="password"
                            onChange={handleChange}
                            required
                        />
                    </animated.div>
                </li>
                <li>
                    <animated.div style={shakeAnimation}>
                        <CustomInput
                            label="повторите пароль"
                            placeholder="введите пароль"
                            value={formData.repPas}
                            type="password"
                            name="repPas"
                            onChange={handleChange}
                            required
                        />
                    </animated.div>
                </li>
            </ul>
            <button className={cl.button} onClick={onRegSubmit}>сохранить</button>
        </form>
    );
}

export default RegisterForm;
