// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.players-count_form__lsidy{
    color: #fff;
    margin: 57px 95px;
    font-family: 'Jura';

    align-items: center;

    display: flex;
    flex-direction: column;

    gap: 60px;
}


.players-count_title__GTddE{
    font-size: 48px;
    font-weight: normal;
    width: 387px;
}

.players-count_radioList__4WjmE {
    display: grid;
    grid-template-columns:1fr 1fr;
    column-gap: 50%;

    justify-items: start;

    width: 328px;
}

.players-count_radioList__4WjmE li:nth-child(1) {
    grid-column: 1;
    grid-row: 1;
}

.players-count_radioList__4WjmE li:nth-child(2) {
    grid-column: 1;
    grid-row: 2;
}

.players-count_radioList__4WjmE li:nth-child(3) {
    grid-column: 1;
    grid-row: 3;
}

.players-count_radioList__4WjmE li:nth-child(4) {
    grid-column: 2;
    grid-row: 1;
    text-align: right;
}

.players-count_radioList__4WjmE li:nth-child(5) {
    text-align: right;
    grid-column: 2;
    grid-row: 2;
}
  
  `, "",{"version":3,"sources":["webpack://./src/components/game-join/game-join-players-count/players-count.module.css"],"names":[],"mappings":";;AAEA;IACI,WAAW;IACX,iBAAiB;IACjB,mBAAmB;;IAEnB,mBAAmB;;IAEnB,aAAa;IACb,sBAAsB;;IAEtB,SAAS;AACb;;;AAGA;IACI,eAAe;IACf,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,6BAA6B;IAC7B,eAAe;;IAEf,oBAAoB;;IAEpB,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,WAAW;AACf;;AAEA;IACI,cAAc;IACd,WAAW;AACf;;AAEA;IACI,cAAc;IACd,WAAW;AACf;;AAEA;IACI,cAAc;IACd,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,cAAc;IACd,WAAW;AACf","sourcesContent":["\n\n.form{\n    color: #fff;\n    margin: 57px 95px;\n    font-family: 'Jura';\n\n    align-items: center;\n\n    display: flex;\n    flex-direction: column;\n\n    gap: 60px;\n}\n\n\n.title{\n    font-size: 48px;\n    font-weight: normal;\n    width: 387px;\n}\n\n.radioList {\n    display: grid;\n    grid-template-columns:1fr 1fr;\n    column-gap: 50%;\n\n    justify-items: start;\n\n    width: 328px;\n}\n\n.radioList li:nth-child(1) {\n    grid-column: 1;\n    grid-row: 1;\n}\n\n.radioList li:nth-child(2) {\n    grid-column: 1;\n    grid-row: 2;\n}\n\n.radioList li:nth-child(3) {\n    grid-column: 1;\n    grid-row: 3;\n}\n\n.radioList li:nth-child(4) {\n    grid-column: 2;\n    grid-row: 1;\n    text-align: right;\n}\n\n.radioList li:nth-child(5) {\n    text-align: right;\n    grid-column: 2;\n    grid-row: 2;\n}\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `players-count_form__lsidy`,
	"title": `players-count_title__GTddE`,
	"radioList": `players-count_radioList__4WjmE`
};
export default ___CSS_LOADER_EXPORT___;
