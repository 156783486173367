// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search_input__BWdrH{
    width: 100%;
    height: 100%;
    padding: 14px;

    background-color: #3F3E3C;
    
    font-size: 24px;
    font-family: 'Jura';


    flex: 1 1;
    border: none;

}
.search_container__RVs\\+F{
    width: 50%;

    position: relative;
    display: flex;
    

    align-items: center;
    gap: 9px;
    
}

.search_img__s-29r {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.search_dropdown__Tgp56 {
    position: absolute;
    top: 100%;
    left: 0;
    width: 92.7%;
    max-height: 200px;
    overflow-y: auto;
    border-radius: 0 0 10px 10px;
    background-color: #fff;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.search_dropdown__Tgp56 li {
    cursor: pointer;
}


.search_searchContainer__fSj\\+g{
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/search/search.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;;IAEb,yBAAyB;;IAEzB,eAAe;IACf,mBAAmB;;;IAGnB,SAAO;IACP,YAAY;;AAEhB;AACA;IACI,UAAU;;IAEV,kBAAkB;IAClB,aAAa;;;IAGb,mBAAmB;IACnB,QAAQ;;AAEZ;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,OAAO;IACP,YAAY;IACZ,iBAAiB;IACjB,gBAAgB;IAChB,4BAA4B;IAC5B,sBAAsB;IACtB,0CAA0C;AAC9C;;AAEA;IACI,eAAe;AACnB;;;AAGA;IACI,WAAW;AACf","sourcesContent":[".input{\n    width: 100%;\n    height: 100%;\n    padding: 14px;\n\n    background-color: #3F3E3C;\n    \n    font-size: 24px;\n    font-family: 'Jura';\n\n\n    flex: 1;\n    border: none;\n\n}\n.container{\n    width: 50%;\n\n    position: relative;\n    display: flex;\n    \n\n    align-items: center;\n    gap: 9px;\n    \n}\n\n.img {\n    width: 20px;\n    height: 20px;\n    margin-right: 10px;\n}\n\n.dropdown {\n    position: absolute;\n    top: 100%;\n    left: 0;\n    width: 92.7%;\n    max-height: 200px;\n    overflow-y: auto;\n    border-radius: 0 0 10px 10px;\n    background-color: #fff;\n    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);\n}\n\n.dropdown li {\n    cursor: pointer;\n}\n\n\n.searchContainer{\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `search_input__BWdrH`,
	"container": `search_container__RVs+F`,
	"img": `search_img__s-29r`,
	"dropdown": `search_dropdown__Tgp56`,
	"searchContainer": `search_searchContainer__fSj+g`
};
export default ___CSS_LOADER_EXPORT___;
