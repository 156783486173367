// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.check-box_radio__aXZAC {
    margin-right: 26px;
    display: none;

  }

.check-box_container__nXyug{
    width: 100%;
}

.check-box_label__76aAn {
    font-size: 24px;
    font-family: 'Jura';
    font-weight: 700;
    border-radius: 10px;
    padding: 17px 52px;

    
    transition: border .6s ease-out,
                background-color .3s ease-out;
}

.check-box_radio__aXZAC[type="checkbox"] + label {
    color: #fff;
    font-size: 24px;
}

.check-box_radio__aXZAC:checked + label {
    background-color:  rgba(247, 223, 0, .4);
    border: 2px solid #F7DF00;
}

.check-box_radio__aXZAC:hover + label {
    border: 2px solid #F7DF00;
    cursor: pointer;
}


`, "",{"version":3,"sources":["webpack://./src/components/buttons/themes-checkox/check-box.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;;EAEf;;AAEF;IACI,WAAW;AACf;;AAEA;IACI,eAAe;IACf,mBAAmB;IACnB,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;;;IAGlB;6CACyC;AAC7C;;AAEA;IACI,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,wCAAwC;IACxC,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,eAAe;AACnB","sourcesContent":[".radio {\n    margin-right: 26px;\n    display: none;\n\n  }\n\n.container{\n    width: 100%;\n}\n\n.label {\n    font-size: 24px;\n    font-family: 'Jura';\n    font-weight: 700;\n    border-radius: 10px;\n    padding: 17px 52px;\n\n    \n    transition: border .6s ease-out,\n                background-color .3s ease-out;\n}\n\n.radio[type=\"checkbox\"] + label {\n    color: #fff;\n    font-size: 24px;\n}\n\n.radio:checked + label {\n    background-color:  rgba(247, 223, 0, .4);\n    border: 2px solid #F7DF00;\n}\n\n.radio:hover + label {\n    border: 2px solid #F7DF00;\n    cursor: pointer;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radio": `check-box_radio__aXZAC`,
	"container": `check-box_container__nXyug`,
	"label": `check-box_label__76aAn`
};
export default ___CSS_LOADER_EXPORT___;
