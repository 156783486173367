// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button_button__-7YYS{
    position: fixed;
    left: 69%;
    top: 40%;

    background-color: transparent;

    transition: color .3s ease-out;
}

.button_text__y4\\+fk{
    font-family: 'Jura';
    font-size: 48px;
    font-weight: 700;
    line-height: 30px;
    
    transition: text-shadow .3s ease-out;
}

.button_fliped__eLDWn{
    top: 42%;
}

.button_button__-7YYS:hover{
    color: #DDC800;
    cursor: pointer;
}

.button_text__y4\\+fk:hover{
    text-shadow: 1px 1px 0 #000, -1px -1px 0 #000;
}

.button_button__-7YYS:active{
    color: #fff;
}

.button_relocate__HFPdU{
    left: 80%;
    top: 36%;
}


@media (min-width: 1854px) {
    .button_button__-7YYS{
        left: 63%;
    }

    .button_relocate__HFPdU{
        left: 80%;   
    }
}


@media (max-width: 1086px) {
    .button_button__-7YYS{
        left: 74%;
        
    }

    .button_relocate__HFPdU{
        left: 90%;
    }
}

@media (max-width: 1440px){
    .button_relocate__HFPdU{
        left: 86%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/buttons/next-form/button.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,SAAS;IACT,QAAQ;;IAER,6BAA6B;;IAE7B,8BAA8B;AAClC;;AAEA;IACI,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;;IAEjB,oCAAoC;AACxC;;AAEA;IACI,QAAQ;AACZ;;AAEA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,6CAA6C;AACjD;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,SAAS;IACT,QAAQ;AACZ;;;AAGA;IACI;QACI,SAAS;IACb;;IAEA;QACI,SAAS;IACb;AACJ;;;AAGA;IACI;QACI,SAAS;;IAEb;;IAEA;QACI,SAAS;IACb;AACJ;;AAEA;IACI;QACI,SAAS;IACb;AACJ","sourcesContent":[".button{\n    position: fixed;\n    left: 69%;\n    top: 40%;\n\n    background-color: transparent;\n\n    transition: color .3s ease-out;\n}\n\n.text{\n    font-family: 'Jura';\n    font-size: 48px;\n    font-weight: 700;\n    line-height: 30px;\n    \n    transition: text-shadow .3s ease-out;\n}\n\n.fliped{\n    top: 42%;\n}\n\n.button:hover{\n    color: #DDC800;\n    cursor: pointer;\n}\n\n.text:hover{\n    text-shadow: 1px 1px 0 #000, -1px -1px 0 #000;\n}\n\n.button:active{\n    color: #fff;\n}\n\n.relocate{\n    left: 80%;\n    top: 36%;\n}\n\n\n@media (min-width: 1854px) {\n    .button{\n        left: 63%;\n    }\n\n    .relocate{\n        left: 80%;   \n    }\n}\n\n\n@media (max-width: 1086px) {\n    .button{\n        left: 74%;\n        \n    }\n\n    .relocate{\n        left: 90%;\n    }\n}\n\n@media (max-width: 1440px){\n    .relocate{\n        left: 86%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `button_button__-7YYS`,
	"text": `button_text__y4+fk`,
	"fliped": `button_fliped__eLDWn`,
	"relocate": `button_relocate__HFPdU`
};
export default ___CSS_LOADER_EXPORT___;
