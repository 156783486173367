import React, { useContext, useEffect, useState } from "react";
import cl from './table.module.css';
import axios from "axios";
import { baseGameUrl } from "../../../../constants/const";
import { FormsContext } from "../../../../context/context";
import { useNavigate } from "react-router-dom";

interface QuestionResults {
    question: number;
    score: number;
    _id: string;
}

interface TeamResults {
    teamName: string;
    results: {
        tour: number;
        totalScore: number;
        questionResults: QuestionResults[];
    };
}

interface FinalResults {
    teamName: string;
    totalScore: number;
    results: {
        tourResults: {
            tour: number,
            srore:number,
        }
    }
}

interface TourProps {
    sequence: number;
    isFinal: boolean;
}

const ResultsTable: React.FC<TourProps> = ({ sequence, isFinal }) => {
    const [results, setResults] = useState<TeamResults[]>([]);
    const [finalRes, setFinalRes] = useState<FinalResults[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [attempts, setAttempts] = useState(0);

    const { user, teamName, setInnerForm, setCurrentGameState } = useContext(FormsContext);
    const nav = useNavigate();

    const endGame = async () => {
        try{


            const response = await axios.post(`${baseGameUrl}remove-team`, { gameId: user.gameId, userId: user._id });


            const tempUser = user;
            localStorage.clear();
            localStorage.setItem('user', JSON.stringify({ ...tempUser, gameId: '' }));

            setInnerForm('');
            setCurrentGameState('');
            nav('/main');
        }catch(e){
            const tempUser = user;
            localStorage.clear();
            localStorage.setItem('user', JSON.stringify({ ...tempUser, gameId: '' }));

            setInnerForm('');
            setCurrentGameState('');
            nav('/main');
        }
       
    };


    useEffect(() => {

        const fetchScores = async () => {
            try {
                if (!isFinal) {
                    const response = await axios.get(`${baseGameUrl}get-tour-results`, {
                        params: { gameId: user.gameId, tour: sequence },
                    });
                    const results = response.data;
                    setResults(results);
                } else {
                    const response = await axios.get(`${baseGameUrl}get-final-results`, {
                        params: { gameId: user.gameId }
                    });
                    const teams = response.data;
                    teams.sort((a: FinalResults, b: FinalResults) => b.totalScore - a.totalScore);
                    setFinalRes(teams);
                }
            } catch (e) {
                console.error('Ошибка при загрузке результатов:', e);
                setIsLoading(true);
            } finally {
                setIsLoading(false);
            }
        };

        const waitForAllResults = async (gameId: string) => {
            const interval = setInterval(async () => {
                const allSubmitted = await checkAllResultsSubmitted(gameId);
                setAttempts((prev) => prev + 1);

                if (allSubmitted || attempts >= 5) {
                    clearInterval(interval);
                    if (!allSubmitted) {
                        setResults([]);  // Устанавливаем пустые данные после 10 попыток
                        setFinalRes([]);
                    }
                    setIsLoading(false);
                    fetchScores();
                }
            }, 1000);
        };

        if (isLoading) {
            waitForAllResults(user.gameId);
        }
        if (isFinal) {
            fetchScores();
        }
    }, [sequence, user.gameId, isFinal, isLoading, attempts, nav, user, setInnerForm, setCurrentGameState]);

    async function checkAllResultsSubmitted(gameId: string) {
        try {
            const response = await axios.get(`${baseGameUrl}check-results`, {
                params: { gameId: gameId }
            });
            const data = response.data;
            return data.allSubmitted;
        } catch (error) {
            console.error('Ошибка при проверке статуса результатов:', error);
            return false;
        }
    }

    return (
        <div className={cl.main}>
            {isLoading ? 'Загрузка результатов...' : (
                <table className={cl.table} border={1}>
                    {!isFinal ?
                        <thead>
                            <tr>
                                <th rowSpan={2}>Игрок/Команда</th>
                                <th colSpan={7}>{"№ вопроса"}</th>
                                <th rowSpan={2}>Сумма баллов</th>
                            </tr>
                            <tr>
                                {[...Array(7)].map((_, index) => (
                                    <td key={index}>{index + 1}</td>
                                ))}
                            </tr>
                        </thead> :
                        <thead>
                            <tr className={cl.trFinal}>
                                <th rowSpan={1}>Место</th>
                                <th colSpan={1}>{"игрок/команда"}</th>
                                <th rowSpan={1}>Сумма баллов</th>
                            </tr>
                        </thead>
                    }
                    <tbody>
                        {!isFinal ?
                            results.map((item, index) => (
                                <tr key={index}>
                                    <td>{`${item.teamName} ${item.teamName === teamName ? '(это вы!)' : ''} `}</td>
                                    {item.results.questionResults.map((result, idx) => (
                                        <td key={idx}>{result.score}</td>
                                    ))}
                                    <td className={cl.result}>{item.results.totalScore}</td>
                                </tr>
                            )) :
                            finalRes.map((item, index) => (
                                <tr
                                    key={index}
                                    className={`${cl.trFinal} ${
                                        index === 0 ? cl.gold : index === 1 ? cl.silver : index === 2 ? cl.bronze : ''
                                    }`}
                                >
                                    <td>{index + 1}</td>
                                    <td>{`${item.teamName} ${item.teamName === teamName ? '(это вы!)' : ''} `}</td>
                                    <td>{item.totalScore}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            )}

            {isFinal && !isLoading ? 
                <button className={cl.exit} onClick={endGame}>Вернуться в меню</button> : 
                ''
            }       
        </div>
    );
};

export default ResultsTable;
