import { useContext, useState } from 'react';
import classes from './user-name.module.css';
import { FormsContext } from '../../../context/context';
import UserInfoPopup from '../user-info/user-info-pupup';

export interface IUsername {
    nickname: string,
}

const Username = ({ nickname }: IUsername) => {
    const [isRollUp, setIsRollUp] = useState(false);
    const { fliped } = useContext(FormsContext);

    const togglePopup = () => {
        setIsRollUp(!isRollUp);
        
    };

    return (
        <div className={`${classes.mainContainer} ${fliped ? classes.fliped : ''}`}>
            <UserInfoPopup isActive={isRollUp} onClose={togglePopup} />

            <div className={`${!fliped ? classes.nickContainer : classes.nickContainerFliped}`} onClick={togglePopup}>
                <p className={classes.nickname + ' font-jura'}>
                    {nickname}
                </p>
            </div>
        </div>
    );
}

export default Username;
