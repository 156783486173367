// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/img/regBack.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auth_container__7XlBo{
    display: flex;
    justify-content: center;
    align-items: center;

    min-height: 100%;

    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: no-repeat ;
    background-position: 50% 50%;
}


.auth_linkLeft__DOzEc{
    position: fixed;
    left: 28%;
}

.auth_linkRight__Rd7QS{
    position: fixed;
    right: 28%;
}`, "",{"version":3,"sources":["webpack://./src/components/auth/auth/auth.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;;IAEnB,gBAAgB;;IAEhB,mDAAkD;IAClD,6BAA6B;IAC7B,4BAA4B;AAChC;;;AAGA;IACI,eAAe;IACf,SAAS;AACb;;AAEA;IACI,eAAe;IACf,UAAU;AACd","sourcesContent":[".container{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    min-height: 100%;\n\n    background: url('../../../assets/img/regBack.png');\n    background-repeat: no-repeat ;\n    background-position: 50% 50%;\n}\n\n\n.linkLeft{\n    position: fixed;\n    left: 28%;\n}\n\n.linkRight{\n    position: fixed;\n    right: 28%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `auth_container__7XlBo`,
	"linkLeft": `auth_linkLeft__DOzEc`,
	"linkRight": `auth_linkRight__Rd7QS`
};
export default ___CSS_LOADER_EXPORT___;
