// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reg_container__iveu8{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.reg_title__QnZin{
    font-size: 36px;
    font-family: 'Kyiv Type Sans';
    font-weight: 350;

    text-align: center;
}

.reg_list__gmYb0{
    color: #1A1A1A;
    font-family: 'Jura';
    font-size: 20px;
    display: flex;
    flex-direction: column;
    gap: 23px;
}

.reg_button__QxWbm{
    border: none;
    background-color: transparent;
    font-size: 24px;
    font-family: 'Jura';
    color: #3f3e3c;
    font-weight: 700;

    border-radius: 20px;
    padding: 15px;

    transition: background-color .5s ease-in;
}

.reg_button__QxWbm:hover{
    background-color: #F7DF00;
    cursor: pointer;
}

.reg_success__eTRey{
    color: green;

    transition: color 1s ease-in;
}`, "",{"version":3,"sources":["webpack://./src/components/auth/reg/reg.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,eAAe;IACf,6BAA6B;IAC7B,gBAAgB;;IAEhB,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,mBAAmB;IACnB,eAAe;IACf,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,YAAY;IACZ,6BAA6B;IAC7B,eAAe;IACf,mBAAmB;IACnB,cAAc;IACd,gBAAgB;;IAEhB,mBAAmB;IACnB,aAAa;;IAEb,wCAAwC;AAC5C;;AAEA;IACI,yBAAyB;IACzB,eAAe;AACnB;;AAEA;IACI,YAAY;;IAEZ,4BAA4B;AAChC","sourcesContent":[".container{\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n}\n\n.title{\n    font-size: 36px;\n    font-family: 'Kyiv Type Sans';\n    font-weight: 350;\n\n    text-align: center;\n}\n\n.list{\n    color: #1A1A1A;\n    font-family: 'Jura';\n    font-size: 20px;\n    display: flex;\n    flex-direction: column;\n    gap: 23px;\n}\n\n.button{\n    border: none;\n    background-color: transparent;\n    font-size: 24px;\n    font-family: 'Jura';\n    color: #3f3e3c;\n    font-weight: 700;\n\n    border-radius: 20px;\n    padding: 15px;\n\n    transition: background-color .5s ease-in;\n}\n\n.button:hover{\n    background-color: #F7DF00;\n    cursor: pointer;\n}\n\n.success{\n    color: green;\n\n    transition: color 1s ease-in;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `reg_container__iveu8`,
	"title": `reg_title__QnZin`,
	"list": `reg_list__gmYb0`,
	"button": `reg_button__QxWbm`,
	"success": `reg_success__eTRey`
};
export default ___CSS_LOADER_EXPORT___;
