// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.help-button_button__GHxv2{
    background: transparent;
    width: 214px;
    text-align: left;
}

.help-button_text__enLrf{
    font-size: 32px;
    font-family: "Jura";
}
.help-button_button__GHxv2:hover{
    text-decoration: underline;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/buttons/help/help-button.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB;AACA;IACI,0BAA0B;IAC1B,eAAe;AACnB","sourcesContent":[".button{\n    background: transparent;\n    width: 214px;\n    text-align: left;\n}\n\n.text{\n    font-size: 32px;\n    font-family: \"Jura\";\n}\n.button:hover{\n    text-decoration: underline;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `help-button_button__GHxv2`,
	"text": `help-button_text__enLrf`
};
export default ___CSS_LOADER_EXPORT___;
