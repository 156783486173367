import React, { useContext, useRef, useEffect, useState } from 'react';
import cl from './user-info.module.css';
import CustomInput from '../../buttons/input/custom-input';
import { FormsContext } from '../../../context/context';
import { useNavigate } from 'react-router-dom';
import { UseLocalStorage } from '../../../context/useLocalStorage';
import axios from 'axios';
import { baseAuthUrl } from '../../../constants/const';
import useShakeAnimation from '../../../utils/useShakeAnimation';
import CustomError from '../../validate/error';
import { animated } from '@react-spring/web';
import ConfirmationModal from '../confirm/confirm';

interface UserInfoPopupProps {
    isActive: boolean;
    onClose: () => void;
}

const UserInfoPopup: React.FC<UserInfoPopupProps> = ({ isActive, onClose }) => {
    const { user, setUser } = useContext(FormsContext);

    const [toogleEdit, setToogleEdit] = useState(false);
    const [newName, setNewName] = UseLocalStorage('newNick', user.username); 

    const[isModalOpen, setIsModalOpen] = useState(false);

    const popupRef = useRef<HTMLDivElement>(null);
    const [errorText, setErrorText] = useState('');
    const [shake, setShake] = useState(false);
    const [success, setSucces] = useState(false);
    const animate = useShakeAnimation(shake);
    const nav = useNavigate();
    const ico = require('../../../assets/img/able-red-input.png');

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (popupRef.current && !popupRef.current.contains(event.target as Node) && !(event.target as HTMLElement).classList.contains(cl.logout) && !(isModalOpen)) {
                setToogleEdit(false);
                onClose();
            }
        };
        setNewName(user.username);
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                setToogleEdit(false);
                onClose();
            }
        };

        if (isActive) {
            document.addEventListener('mousedown', handleClickOutside);
            document.addEventListener('keydown', handleKeyDown);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleKeyDown);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [isActive, onClose, nav, setNewName, user.username]);

    const handleLogOut = () => {
        console.log(1);
        if(user.gameId){
            setUser({...user, gameId: ''});
        }
        
        localStorage.clear();
        onClose();
        nav('/auth/log');
    };
    
    const handleEditToogle = () => {
        setToogleEdit(!toogleEdit);
        if(toogleEdit){
            setNewName(user.username);
        }
    }

    const handleSubmit = async () => {
        try {
            const response = await axios.put(`${baseAuthUrl}update-username`, { userId: user._id, newUsername: newName });
            setUser(response.data.user);
            setNewName(response.data.user.username);
            setSucces(true);
            setTimeout(() => {setSucces(false); setToogleEdit(false)}, 3000)
        } catch (e) {
            setShake(true);
            setTimeout(() => { setShake(false); setErrorText(''); }, 2000);
            console.log(e);
            if (axios.isAxiosError(e)) {
                setErrorText(e.response?.data.error);
            }
        }
    }

    const handleNickChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setNewName(value);
    };
    
    return (
        <div className={`${isActive ? '' : cl.hidden} ${cl.rollup}`}>
            <div className={cl.container} ref={popupRef}>
                <main className={cl.main}>
                    <h2 className={cl.title}>Личный кабинет игрока</h2>
                    <div className={cl.contols}>
                        <div className={cl.stats}>
                            <h2 className={cl.subtitle}>{`${success ? 'Данные успешно обновлены' : 'Информация об игроке'}`}</h2>
                            <div className={cl.stats_info}>
                                <ul className={cl.list}>
                                    <li>
                                        <CustomInput
                                            label='ваше имя'
                                            placeholder={newName}
                                            id='nickname'
                                            value={newName}
                                            type="text"
                                            name="nickname"
                                            onChange={handleNickChange}
                                            disabled={!toogleEdit}
                                        />
                                    </li>
                                    <li>
                                        <CustomInput
                                            label='логин'
                                            placeholder={user.email}
                                            id='login'
                                            value={user.email}
                                            type="text"
                                            name="login"
                                            disabled
                                        />
                                    </li>
                                </ul>
                                <div className={cl.red_buttons}>
                                    <img src={ico} className={cl.img} onClick={handleEditToogle} alt="" width={'25px'} height={'25px'} />
                                </div>
                            </div>
                            {toogleEdit && !errorText && (
                                <button disabled={success} className={`${cl.submit} ${success ? cl.success : ''}` } onClick={handleSubmit}>Изменить</button>
                            )}
                            {errorText && (
                                <animated.div style={animate}>
                                    <CustomError errorText={errorText} />
                                </animated.div>
                            )}
                        </div>

                        {/* <div className={cl.stats}>
                            <h2 className={cl.subtitle}>Тут скоро будет ваша статистика</h2>
                            <div className={`${cl.stats_info} ${cl.hide_data}`}>
                                <ul className={cl.list}>
                                    <li className={cl.stats_item}>
                                        количество сыгранных игр <span>?</span>
                                    </li>
                                    <li className={cl.stats_item}>
                                        количество одержанных побед <span>?</span>
                                    </li>
                                </ul>
                            </div>
                        </div> */}

                    </div>

                </main>
                <button className={cl.logout} onClick={() => setIsModalOpen(true)}>Выход</button>
            </div>
            <ConfirmationModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onConfirm={handleLogOut}
                message="Точно выйти из аккаунта?"
            />
        </div>
    );




}

export default UserInfoPopup;
