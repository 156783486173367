// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.game-hint_container__bT-b5{
    display: flex;
    max-height: 302px;
    width: 24%;
    margin-right: 57px;
    margin-left: auto;
}

.game-hint_img__skDqc{
    object-fit: contain;

}

.game-hint_buttonsСontainer__DML2w{
    display: flex;
    flex-direction: column;
    margin-top: 52px;
}

@media (max-width: 1439px) {
    .game-hint_img__skDqc{
         display: none;
    }

}`, "",{"version":3,"sources":["webpack://./src/components/game-menu/hint/game-hint.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,iBAAiB;IACjB,UAAU;IACV,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;;AAEvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI;SACK,aAAa;IAClB;;AAEJ","sourcesContent":[".container{\n    display: flex;\n    max-height: 302px;\n    width: 24%;\n    margin-right: 57px;\n    margin-left: auto;\n}\n\n.img{\n    object-fit: contain;\n\n}\n\n.buttonsСontainer{\n    display: flex;\n    flex-direction: column;\n    margin-top: 52px;\n}\n\n@media (max-width: 1439px) {\n    .img{\n         display: none;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `game-hint_container__bT-b5`,
	"img": `game-hint_img__skDqc`,
	"buttonsСontainer": `game-hint_buttonsСontainer__DML2w`
};
export default ___CSS_LOADER_EXPORT___;
