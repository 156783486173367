import React, { InputHTMLAttributes } from 'react';
import classes from './custom-input.module.css';

interface CustomInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  addClass?: boolean;
  AddClassName?: string;
}

const CustomInput: React.FC<CustomInputProps> = ({ label, addClass, AddClassName,  ...props }) => {
  return (
    <div className={`${classes.container} ${AddClassName ? classes.findGame : ''} ${addClass ? classes.addClass : ''}`}>
      <label className={`${classes.label}`} htmlFor={props.id}>{label}</label>
      <input {...props} className={`${classes.input}`} />
    </div>
  );
};

export default CustomInput;
